import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormLabel, InputAdornment, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ModelSponsorshipLevel,
  useSponsorshipLevelDelete,
  useSponsorshipLevelIdGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { hasPermission } from "@services/Casbin";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
export const SponsorshipLevelView = () => {
  const navigate = useNavigate();
  const organizationId = useRecoilValue(organizationAtom);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [sponsorToDelete, setSponsorToDelete] =
    useState<ModelSponsorshipLevel | null>(null);

  const [sponsoredByAd, setSponsoredBy] = useState(true);
  const [outStreamAd, setOutStreamAd] = useState(true);
  const [inStreamAd, setInstreamAd] = useState(false);
  const [feedPostAd, setFeedPostAd] = useState(false);

  const { control, reset, setValue } = useForm({
    mode: "onBlur"
  });
  const { data: sponsorshipLevel, isLoading: sponsorshipLevelLoading } =
    useSponsorshipLevelIdGet(id!);
  const getOptions = (max: number) => {
    const options: { label: string; value: string }[] = [];
    for (let i = 1; i <= 100 - max; i++) {
      options.push({
        label: `${i}%`,
        value: `${i}`
      });
    }
    return options;
  };
  useEffect(() => {
    if (sponsorshipLevel?.data) {
      reset({
        name: sponsorshipLevel?.data?.sponsorshipLevel?.name,
        isActive: sponsorshipLevel.data.sponsorshipLevel?.isActive
          ? "YES"
          : "NO",
        amount: sponsorshipLevel.data.sponsorshipLevel?.amount,
        inStreamAd: sponsorshipLevel?.data?.sponsorshipLevel?.inStreamEnabled,
        outStreamAd: sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled,
        feedPostAd: !!sponsorshipLevel?.data?.sponsorshipLevel?.feedPostEnabled,
        sponsoredByAd:
          sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled
      });
      if (
        sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled &&
        sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByPercentage
      ) {
        setValue(
          "sponsoredByAd_percentage",
          String(
            Math.round(
              sponsorshipLevel.data.sponsorshipLevel?.sponsoredByPercentage *
                100
            )
          )
        );
        sponsorshipLevel?.data.adPlacementPercentages
          ?.filter((level) => level.sponsorshipId != id)
          .filter((level) => level.sponsoredByEnabled)
          .map((level) => {
            setValue(
              `sponsoredByAd_${level.name}`,
              String(Math.round(level.sponsoredByPercentage * 100))
            );
          });
      }
      if (
        sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled &&
        sponsorshipLevel?.data?.sponsorshipLevel?.outStreamPercentage
      ) {
        setValue(
          "outStreamAd_percentage",
          String(
            Math.round(
              sponsorshipLevel.data.sponsorshipLevel?.outStreamPercentage * 100
            )
          )
        );
        sponsorshipLevel?.data.adPlacementPercentages
          ?.filter((level) => level.sponsorshipId != id)
          .filter((level) => level.outStreamEnabled)
          .map((level) => {
            setValue(
              `outStreamAd_${level.name}`,
              String(Math.round(level.outStreamPercentage * 100))
            );
          });
      }

      if (sponsorshipLevel?.data?.sponsorshipLevel?.inStreamEnabled) {
        setValue(
          "duration",
          String(sponsorshipLevel?.data?.sponsorshipLevel?.inStreamMaxDuration)
        );
      }

      if (
        sponsorshipLevel?.data?.sponsorshipLevel?.feedPostEnabled &&
        sponsorshipLevel?.data?.sponsorshipLevel?.feedPostPercentage
      ) {
        setValue(
          "feedPostAd_percentage",
          String(
            Math.round(
              sponsorshipLevel.data.sponsorshipLevel?.feedPostPercentage * 100
            )
          )
        );
        sponsorshipLevel?.data.adPlacementPercentages
          ?.filter((level) => level.sponsorshipId != id)
          .filter((level) => level.feedPostEnabled)
          .map((level) => {
            setValue(
              `feedPostAd_${level.name}`,
              String(Math.round(level.feedPostPercentage * 100))
            );
          });
      }

      setSponsoredBy(
        sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled || false
      );
      setOutStreamAd(
        sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled || false
      );
      setInstreamAd(
        sponsorshipLevel?.data?.sponsorshipLevel?.inStreamEnabled || false
      );
      setFeedPostAd(
        sponsorshipLevel?.data?.sponsorshipLevel?.feedPostEnabled || false
      );
    }
  }, [sponsorshipLevel]);
  const [permissions, setPermissions] = useState({
    delete: false
  });

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("live-streaming.sponsors", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  const renderSub = (Adtype: string, levels) => {
    return (
      <>
        <Grid sx={{ paddingLeft: "12px" }} xs={12} md={6} sm={12} spacing={3}>
          <FormSelect
            label="Probability Percentage of being Displayed"
            name={`${Adtype}_percentage`}
            disabled={true}
            options={getOptions(0)}
            control={control}
          />
        </Grid>
        {levels && levels.length >= 1 && (
          <>
            <Grid
              container
              spacing={3}
              xs={12}
              md={6}
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F4F4F4"
              }}
            >
              {levels &&
                levels.map((level) => (
                  <Grid key={level.sponsorshipId} xl={4} md={4} sm={12}>
                    <FormSelect
                      label={level.name}
                      name={`${Adtype}_${level.name}`}
                      options={getOptions(0)}
                      control={control}
                      disabled={true}
                    />
                  </Grid>
                ))}
            </Grid>
          </>
        )}
        {levels && levels.length == 0 && (
          <Grid
            data-testid="sponsorship-level-note"
            spacing={3}
            xs={12}
            md={6}
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F4F4F4",
              height: "fit-content",
              marginTop: "28px"
            }}
          >
            <Typography>
              Note: Because this ad placement is only included in one
              sponsorship level it has been automatically set to be displayed
              100% of the time.
            </Typography>
          </Grid>
        )}
      </>
    );
  };
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useSponsorshipLevelDelete();

  const onConfirmDelete = async () => {
    if (!sponsorToDelete?.sponsorshipId) return;
    try {
      await deleteAsync({ sponsorshipId: sponsorToDelete.sponsorshipId });
      enqueueSnackbar("Sponsorship Level deleted successfully", {
        variant: "success"
      });
      setSponsorToDelete(null);
      navigate("/sponsorship-levels");
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete sponsorship level.",
        {
          variant: "error"
        }
      );
      setSponsorToDelete(null);
    }
  };
  const isDeleteDisabled = (row) => {
    return row?.isActive || row?.advertisers?.length > 0;
  };
  return (
    <Loader isLoading={sponsorshipLevelLoading}>
      <Container>
        <Toolbar
          title="View Sponsorship Level"
          backBtnClick={() => navigate("/sponsorship-levels")}
          editBtnClick={() => navigate(`/sponsorship-levels/${id}/edit`)}
          {...(!isDeleteDisabled(sponsorshipLevel?.data?.sponsorshipLevel) &&
            permissions.delete && {
              deleteBtnClick: () =>
                setSponsorToDelete(sponsorshipLevel!.data.sponsorshipLevel!)
            })}
        />
        <Form>
          <Grid data-testid="sponsorship-level-form" container spacing={3}>
            <Grid container direction="row" spacing="24px" xs={12}>
              <Grid data-testid="sponsorship-level-name" xs={6}>
                <FormInput
                  control={control}
                  name="name"
                  type="text"
                  label="Name"
                  disabled
                  required={true}
                  rules={{
                    required: "Name is required"
                  }}
                />
              </Grid>
              <Grid xs={6}>
                <FormInput
                  type="number"
                  control={control}
                  label="Amount"
                  name="amount"
                  disabled
                  required
                  rules={{
                    required: "Amount is required"
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{"$"}</InputAdornment>
                    ),
                    step: "0.1"
                  }}
                />
              </Grid>
            </Grid>
            <Grid data-testid="sponsorship-level-isActive" xs={6}>
              <FormSelect
                control={control}
                disabled
                options={[
                  {
                    label: "Yes",
                    value: "YES"
                  },
                  {
                    label: "No",
                    value: "NO"
                  }
                ]}
                name="isActive"
                label="Is Active"
              />
            </Grid>
            <Grid xs={12}>
              <StyledFormLabel required={true}>
                <Typography
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                  display="inline"
                  variant="formLabel"
                >
                  Ad Placements
                </Typography>
              </StyledFormLabel>

              {!organizationId && (
                <Grid
                  container
                  xs={12}
                  md={12}
                  sm={12}
                  xl={12}
                  sx={{ alignItems: "center" }}
                >
                  <FormCheckbox
                    label="Feed Post Ad - Feed/Recruiting Feed Posts"
                    name="feedPostAd"
                    control={control}
                    disabled={true}
                  />
                  {feedPostAd && (
                    <>
                      {renderSub(
                        "feedPostAd",
                        sponsorshipLevel?.data.adPlacementPercentages
                          ?.filter((level) => level.sponsorshipId != id)
                          .filter((level) => level.feedPostEnabled)
                      )}
                    </>
                  )}
                </Grid>
              )}
              <Grid
                container
                xs={12}
                md={12}
                sm={12}
                xl={12}
                sx={{ alignItems: "center" }}
              >
                <FormCheckbox
                  label="Sponsored By Ad - Live Stream Event Feed Posts"
                  name="sponsoredByAd"
                  control={control}
                  disabled={true}
                />
                {sponsoredByAd && (
                  <>
                    {renderSub(
                      "sponsoredByAd",
                      sponsorshipLevel?.data.adPlacementPercentages
                        ?.filter((level) => level.sponsorshipId != id)
                        .filter((level) => level.sponsoredByEnabled)
                    )}
                  </>
                )}
              </Grid>
              <Grid container xs={12} md={12} sm={12} xl={12}>
                <FormCheckbox
                  label="Out-Stream Ad - Live Stream Intermissions"
                  name="outStreamAd"
                  control={control}
                  disabled={true}
                />
                {outStreamAd && (
                  <>
                    {renderSub(
                      "outStreamAd",
                      sponsorshipLevel?.data.adPlacementPercentages
                        ?.filter((level) => level.sponsorshipId != id)
                        .filter((level) => level.outStreamEnabled)
                    )}
                  </>
                )}
              </Grid>
              <Grid container xs={12} md={12} sm={12} xl={12}>
                <FormCheckbox
                  label="In-Stream Ad - Live Stream Intermissions"
                  name="inStreamAd"
                  control={control}
                  disabled={true}
                />
                {inStreamAd && (
                  <>
                    <Grid xs={6}>
                      <FormSelect
                        label="Max Duration of In-Stream Video"
                        name="duration"
                        disabled
                        required={inStreamAd}
                        options={[
                          {
                            label: "6 seconds",
                            value: "6"
                          },
                          {
                            label: "15 seconds",
                            value: "15"
                          },
                          {
                            label: "30 seconds",
                            value: "30"
                          }
                        ]}
                        control={control}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Form>
      </Container>
      <ConfirmationDialog
        open={!!sponsorToDelete}
        title="Delete Sponsorship Level"
        body={`Are you sure you want to delete ${sponsorToDelete?.name}?`}
        close={() => setSponsorToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setSponsorToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Loader>
  );
};
