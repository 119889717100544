import { FormInput } from "@components/FormInput";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import {
  Box,
  Typography,
  styled,
  FormLabel,
  Autocomplete,
  TextField,
  InputAdornment,
  MenuItem
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminLiveStreamStreamIdDelete,
  useAdminLiveStreamStreamIdGet,
  useAdminSportGet,
  useAdminTeamGet,
  useAdminTrainingProgramGet,
  ModelSetting,
  useConfigGet,
  useAdminSettingsGet,
  useAdminSportLocationGet,
  ModelStream
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormSelect } from "@components/FormSelect";
import { useRecoilValue } from "recoil";
import { organizationAtom, organizationsAtom } from "@recoil/auth";
import { FormDatePicker } from "@components/FormDatePicker";
import { InfoIcon } from "@components/Icons";
import { FormCheckbox } from "@components/FormCheckbox";
import { FormTimePicker } from "@components/FormTimePicker";
import { Loader } from "@components/crud/Loader";
import { parseISO } from "date-fns";
import { useSnackbar } from "notistack";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { hasPermission } from "@services/Casbin";
import { Search } from "@mui/icons-material";
import { SearchInput } from "@components/SearchInput";
import { LiveStreamPlayer } from "./LiveStreamPlayer";

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledDiv = styled("div")(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%"
  }
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const LiveStreamView = () => {
  const timeZoneOptions = Intl.supportedValuesOf("timeZone").map((key) => {
    return {
      label: key,
      value: key
    };
  });
  const organizationId = useRecoilValue(organizationAtom);
  const organizations = useRecoilValue(organizationsAtom);
  const organization = organizations.find(
    (org) => org.organizationId === organizationId
  );
  const navigate = useNavigate();
  const { id } = useParams()!;
  const [viewLive, setViewLive] = useState(false);
  const [status, setStatus] = useState("NOT_STARTED");
  const { enqueueSnackbar } = useSnackbar();
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const [contestType, setContestType] = useState();
  const [teamId, setTeamId] = useState<string>();
  const [opponent, setOpponent] = useState<string>();
  const [programId, setProgramId] = useState<string>();
  const [sportId, setSportId] = useState();

  const [LiveStreamToDelete, setLiveStreamToDelete] =
    useState<ModelStream | null>(null);
  const { data: sports, isLoading: sportOptionsLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const { data: team, isLoading: teamLoading } = useAdminTeamGet({
    organizationId: organizationId!,
    pageSize: 1000
  });
  const { data: trainingProgram, isLoading: trainingProgramLoading } =
    useAdminTrainingProgramGet({
      organizationId: organizationId!,
      pageSize: 1000
    });

  const [sportLocationInputValue, setSportLocationInputValue] =
    useState<string>("");

  const [sportLocation, setSportLocation] = useState<string | undefined>(
    undefined
  );

  const { data: orgSportsLocations } = useAdminSportLocationGet({
    organizationId: organizationId!
  });

  const shareWithOptions = useMemo(() => {
    const orgOption = [
      {
        label: "My Organization",
        value: organizationId!
      },
      ...(contestType === "TRAINING_SESSION"
        ? [
            {
              label: "Training Program",
              value: "TRAINING_PROGRAM"
            }
          ]
        : [
            {
              label: "Team",
              value: "TEAM"
            }
          ])
    ];
    return [...orgOption];
  }, [teamId, programId, contestType]);

  const teamOptions = useMemo(() => {
    const teamOptions =
      (team?.data.teams &&
        team?.data?.teams.map((team) => ({
          label: team.name!,
          value: team.teamId,
          data: team
        }))) ||
      [];
    return [...teamOptions];
  }, [team]);

  const programOptions = useMemo(() => {
    const trainingProgramOptions =
      (trainingProgram?.data.trainingPrograms &&
        trainingProgram?.data?.trainingPrograms.map((trainingProgram) => ({
          label: trainingProgram.name!,
          value: trainingProgram.programId,
          data: trainingProgram
        }))) ||
      [];

    return [...trainingProgramOptions];
  }, [trainingProgram]);

  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId
      })) || [],
    [sports]
  );

  const contestTypeOptions = useMemo(() => {
    const options = [
      {
        label: "Game",
        value: "GAME"
      },
      {
        label: "Scrimmage",
        value: "SCRIMMAGE"
      },
      {
        label: "Practice",
        value: "PRACTICE"
      },
      ...(organization?.offering?.includes("TRAINING_PROGRAMS")
        ? [
            {
              label: "Training Session",
              value: "TRAINING_SESSION"
            }
          ]
        : []),
      {
        label: "Event",
        value: "EVENT"
      }
    ];
    if (sportId && sports?.data?.length) {
      const sport = sports?.data.find((sp) => sp.sportId === sportId);
      const isMatch = sport?.countries?.find(
        (c) => c.countryId === organization?.country || "US"
      )?.isMatch;
      if (isMatch) options[0].label = "Match";
    }
    return options;
  }, [sportId, sports]);

  const { data: liveStream, isLoading: liveStreamLoading } =
    useAdminLiveStreamStreamIdGet(id!);
  const selectedTags = useMemo(() => {
    const selectedTags =
      (liveStream?.data.tags &&
        liveStream?.data?.tags.map((tag) => ({
          label: tag!
        }))) ||
      [];
    return [...selectedTags];
  }, [liveStream?.data.tags]);
  const { control, reset, getValues } = useForm({
    mode: "onBlur"
  });
  const hasOpposing = () => {
    if (
      !contestType ||
      contestType === "TRAINING_SESSION" ||
      contestType === "PRACTICE"
    )
      return false;
    return true;
  };

  const { data: settings, isLoading: isLoadingSettings } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "org-live-stream"
      });

  const {
    data: settingTimeInterval,
    isLoading: isLoadingSettingsTimeInterval
  } = !organizationId
    ? useConfigGet()
    : useAdminSettingsGet({
        organizationId: organizationId!,
        parentId: "general"
      });
  useEffect(() => {
    if (settingTimeInterval && organizationId) {
      const settingsOrg = settingTimeInterval.data as ModelSetting[];
      const timeIntervalSetting = settingsOrg.find(
        (s) => s.settingId === "general.default-time-picker-interval"
      );
      if (timeIntervalSetting) {
        const orgSetting =
          timeIntervalSetting.organizationSettings &&
          timeIntervalSetting.organizationSettings.length > 0
            ? timeIntervalSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )?.value
            : timeIntervalSetting.value || timeIntervalSetting.default;
        if (orgSetting) {
          const numericValue = parseInt(orgSetting.match(/\d+/)[0], 10);
          const unit = orgSetting.match(/[A-Z]+/)[0];

          if (unit === "MIN") {
            setTimeInterval(numericValue);
          } else if (unit === "H") {
            setTimeInterval(numericValue * 60);
          }
        }
      }
    }
  }, [settingTimeInterval]);

  const [allowPublicSharing, setAllowPublicSharing] = useState(false);
  const [allowPreGame, setAllowPreGame] = useState(false);
  const [allowPostGame, setAllowPostGame] = useState(false);
  const [timeInterval, setTimeInterval] = useState(15);
  useEffect(() => {
    if (settings && organizationId) {
      const settingsOrg = settings.data as ModelSetting[];
      const publicSharingSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-public-sharing"
      );
      const preGameSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-pre-game"
      );
      const postGameSetting = settingsOrg.find(
        (s) => s.settingId === "org-live-stream.allow-post-game"
      );

      if (publicSharingSetting) {
        const orgSetting =
          publicSharingSetting.organizationSettings &&
          publicSharingSetting.organizationSettings.length > 0
            ? publicSharingSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPublicSharing(Boolean(orgSetting.value));
      }
      if (preGameSetting) {
        const orgSetting =
          preGameSetting.organizationSettings &&
          preGameSetting.organizationSettings.length > 0
            ? preGameSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPreGame(Boolean(orgSetting.value));
      }
      if (postGameSetting) {
        const orgSetting =
          postGameSetting.organizationSettings &&
          postGameSetting.organizationSettings.length > 0
            ? postGameSetting.organizationSettings.find(
                (o) => o.organizationId === organizationId
              )
            : null;
        if (orgSetting) setAllowPostGame(Boolean(orgSetting.value));
      }
    }
  }, [settings]);

  useEffect(() => {
    if (!liveStreamLoading && liveStream?.data) {
      const defaultValues = {
        icon: "",
        organization: organization?.name || "SportsGravy LLC",
        title: liveStream?.data.title,
        status: liveStream.data.status,
        sportId: liveStream.data.sportId,
        shareTo: liveStream?.data.teamId
          ? "TEAM"
          : liveStream?.data.programId
          ? "PROGRAM"
          : organizationId,
        //@ts-ignore
        scheduledDate: parseISO(liveStream?.data?.scheduledAt),
        //@ts-ignore
        scheduledTime: parseISO(liveStream.data.scheduledAt),
        //@ts-ignore
        scheduledEndAt: parseISO(liveStream.data.scheduledEndAt),
        timeZone: liveStream.data.timeZone,
        location: liveStream.data.location,
        locationCheckbox: true,
        preEvent: !!liveStream.data.preEventAt,
        postEvent: !!liveStream.data.postEventAt,
        isPublic: liveStream.data.isPublic,
        notes: liveStream.data.notes,
        teamId: liveStream.data.teamId,
        programId: liveStream.data.programId,
        opponent: liveStream.data.opponent,
        isHomeTeam: !!liveStream.data.isHomeTeam,
        contestType: liveStream.data.contestType || "GAME",
        createdBy:
          liveStream.data.createdBy?.person?.firstName +
          " " +
          liveStream.data.createdBy?.person?.lastName
      };
      setStatus(liveStream.data.status || "NOT_STARTED");
      //@ts-ignore
      if (liveStream?.data?.preEventAt) {
        //@ts-ignore
        defaultValues["preEventAt"] = parseISO(liveStream?.data?.preEventAt);
        if (liveStream.data.preEventEndAt)
          defaultValues["preEventEndAt"] = parseISO(
            liveStream?.data?.preEventEndAt
          );
      }
      if (liveStream?.data?.postEventAt) {
        //@ts-ignore
        defaultValues["postEventAt"] = parseISO(liveStream?.data?.postEventAt);
        if (liveStream.data.postEventEndAt)
          defaultValues["postEventEndAt"] = parseISO(
            liveStream?.data?.postEventEndAt
          );
      }
      if (
        liveStream?.data?.streamedBy?.person?.firstName &&
        liveStream?.data?.streamedBy?.person?.lastName
      ) {
        defaultValues["streamedBy"] =
          liveStream?.data?.streamedBy?.person?.firstName +
          " " +
          liveStream?.data?.streamedBy?.person?.lastName;
      }

      if (orgSportsLocations?.data) {
        defaultValues["locationCheckbox"] = false;
        if (liveStream.data.sportLocationId) {
          setSportLocation(liveStream.data.sportLocationId);
          setSportLocationInputValue(liveStream.data.sportLocation?.name);
          defaultValues["location"] = liveStream.data.sportLocation?.name;
          if (liveStream.data.sportLocation?.name === organization?.name)
            defaultValues["locationCheckbox"] = true;
        } else {
          setSportLocation(undefined);
          setSportLocationInputValue(liveStream.data.location);
          defaultValues["location"] = liveStream.data.location;
        }
      }

      //@ts-ignore
      reset(defaultValues);

      setSportId(liveStream.data.sportId || "");
      setContestType(liveStream.data.contestType || "GAME");
      setOpponent(liveStream.data.opponent || "");
      setTeamId(liveStream.data.teamId);
      setProgramId(liveStream.data.programId);
    }
  }, [liveStream, liveStreamLoading, orgSportsLocations]);
  const handleViewLiveClose = () => {
    setViewLive(false);
  };
  const { mutateAsync, isLoading } = useAdminLiveStreamStreamIdDelete();

  const onConfirmDelete = async () => {
    if (!LiveStreamToDelete?.sportId) return;
    try {
      await mutateAsync({ streamId: LiveStreamToDelete.streamId! });
      enqueueSnackbar("Live stream deleted successfully", {
        variant: "success"
      });
      setLiveStreamToDelete(null);
      navigate("/live-stream");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete live stream", {
        variant: "error"
      });
      setLiveStreamToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("organization.post", "ON");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Loader
      isLoading={
        liveStreamLoading || isLoadingSettings || isLoadingSettingsTimeInterval
      }
    >
      <Container>
        <Toolbar
          title="View Live Stream"
          backBtnClick={() => navigate("/live-stream")}
          {...(permissions.delete &&
            status === "NOT_STARTED" && {
              deleteBtnClick: () => setLiveStreamToDelete(liveStream!.data)
            })}
          {...(["PAUSED", "INTERMISSION", "LIVE"].includes(
            liveStream?.data.status as string
          )
            ? {
                liveBtnClick: () => setViewLive(true)
              }
            : status === "NOT_STARTED" && {
                editBtnClick: () => navigate(`/live-stream/${id}/edit`)
              })}
        />

        <Form>
          <Grid data-testid="live-add-form" container spacing={3}>
            <Grid xs={12} md={12}>
              {liveStream?.data.status === "LIVE" && (
                <Container>
                  <Grid
                    sx={{
                      display: "flex",
                      background: "#F5F5F5",
                      borderRadius: "6px",
                      padding: "12px"
                    }}
                  >
                    <InfoIcon sx={{ marginRight: "8px" }} />
                    <Typography>
                      You can interact with your live streams via the feed in
                      your web app or through the SportsGravy Mobile App as
                      well.
                    </Typography>
                  </Grid>
                </Container>
              )}
              {liveStream?.data.thumbnail && (
                <>
                  <Grid xs={12} md={12}>
                    <StyledFormLabel required={true}>
                      <Typography display="inline" variant="formLabel">
                        {"Thumbnail"}
                      </Typography>
                    </StyledFormLabel>
                  </Grid>
                  <Grid container spacing={3} xs={12} md={12}>
                    <Grid xs={12} md={4}>
                      <img
                        src={
                          liveStream.data.thumbnail.baseUrl &&
                          liveStream?.data?.thumbnail?.baseUrl +
                            liveStream?.data?.thumbnail?.path
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain"
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid xs={12} md={6} data-testid="liveSteam-organization">
              <FormInput
                control={control}
                name="organization"
                type="text"
                label="Organization"
                required={true}
                disabled
              />
            </Grid>

            <Grid xs={12} md={6} data-testid="liveSteam-sport">
              <FormSelect
                control={control}
                required
                name="sportId"
                label="Sport"
                options={sportOptions}
                isLoading={sportOptionsLoading}
                disabled
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>

            <Grid xs={12} md={6} data-testid="liveSteam-contestType">
              <FormSelect
                control={control}
                required
                name="contestType"
                label="Contest Type"
                options={contestTypeOptions}
                disabled
                rules={{
                  required: "Contest Type is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="liveSteam-team-program">
              <FormSelect
                control={control}
                required
                name={
                  contestType === "TRAINING_SESSION" ? "programId" : "teamId"
                }
                label={
                  contestType === "TRAINING_SESSION"
                    ? "Training Program"
                    : "Team"
                }
                options={
                  contestType === "TRAINING_SESSION"
                    ? programOptions
                    : teamOptions
                }
                disabled
                rules={{
                  required:
                    contestType === "TRAINING_SESSION"
                      ? "Training Program is required"
                      : "Team is required"
                }}
              />
              {hasOpposing() && contestType !== "EVENT" && (
                <div>
                  <FormCheckbox
                    disabled
                    control={control}
                    name="isHomeTeam"
                    label="Home Team"
                  />
                </div>
              )}
            </Grid>
            {hasOpposing() && (
              <Grid xs={12} md={6} data-testid="liveSteam-opponent">
                <FormInput
                  control={control}
                  name="opponent"
                  type="text"
                  disabled
                  label={`Opposing ${
                    contestType === "EVENT" ? "Organization" : "Team"
                  }`}
                  required={true}
                  rules={{
                    required: "Opponent is required",
                    maxLength: {
                      value: 50,
                      message: "Opponent Name must not exceed 50 characters"
                    }
                  }}
                />
                <div style={{ textAlign: "end" }}>
                  <Typography style={{ opacity: "50%" }}>
                    {"Character Count Remaining: " +
                      (50 - (opponent?.length || 0))}
                  </Typography>
                </div>
              </Grid>
            )}

            <Grid xs={12} md={6} data-testid="liveSteam-title">
              <FormInput
                control={control}
                name="title"
                type="text"
                label="Title"
                required={true}
                disabled
                rules={{
                  required: "Title is required",
                  maxLength: {
                    value: 70,
                    message: "Title must not exceed 70 characters"
                  }
                }}
              />
              <div style={{ textAlign: "end" }}>
                <Typography style={{ opacity: "50%" }}>
                  {"Character Count Remaining: " +
                    (70 - getValues().title?.length || 0)}
                </Typography>
              </div>
            </Grid>
            <Grid xs={12} md={hasOpposing() ? 12 : 6} container direction="row">
              <Grid xs={hasOpposing() ? 6 : 12} data-testid="liveSteam-shareTo">
                <FormSelect
                  control={control}
                  name="shareTo"
                  label="Share With"
                  required={!!organizationId}
                  disabled
                  // disabled={true}
                  options={shareWithOptions}
                  isLoading={teamLoading || trainingProgramLoading}
                  rules={
                    organizationId
                      ? {
                          required: "Share With is required"
                        }
                      : {}
                  }
                />
                {allowPublicSharing && (
                  <div style={{ marginTop: "14px" }}>
                    <FormCheckbox
                      control={control}
                      name="isPublic"
                      disabled
                      label="Make available to the public"
                    />
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleDate"
            >
              <FormDatePicker
                name="scheduledDate"
                required
                disabled
                control={control}
                label="Event Date"
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleTime"
            >
              <FormTimePicker
                name="scheduledTime"
                timeSteps={{ minutes: timeInterval }}
                required
                disabled
                control={control}
                label="Event Start Time"
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-scheduleEndTime"
            >
              <FormTimePicker
                timeSteps={{ minutes: timeInterval }}
                control={control}
                required={true}
                name="scheduledEndAt"
                label="Event End Time"
                disabled
              />
            </Grid>
            <Grid
              xs={12}
              md={6}
              sm={12}
              xl={3}
              data-testid="liveSteam-timeZone"
            >
              <FormSelect
                control={control}
                required
                name="timeZone"
                label="Event Time Zone"
                options={timeZoneOptions}
                disabled
              />
            </Grid>
            <Grid xs={12} container direction={"row"}>
              <Grid
                container
                xs={12}
                md={6}
                sm={12}
                xl={12}
                flexDirection={"row"}
                spacing="0px"
                alignItems={"center"}
              >
                {allowPreGame && (
                  <Grid xl={3} md={12}>
                    <FormCheckbox
                      control={control}
                      name="preEvent"
                      label="Schedule Pre Game"
                      disabled
                    />
                  </Grid>
                )}
                {!!liveStream?.data.preEventAt && (
                  <>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        timeSteps={{ minutes: timeInterval }}
                        name="preEventAt"
                        control={control}
                        label="Start Time"
                        disabled
                      />
                    </Grid>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        name="preEventEndAt"
                        control={control}
                        label="End Time"
                        disabled={true}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid xs={12} container direction={"row"}>
              <Grid
                container
                xs={12}
                md={6}
                sm={12}
                xl={12}
                flexDirection={"row"}
                spacing="10px"
                alignItems={"center"}
              >
                {allowPostGame && (
                  <Grid xl={3} md={12}>
                    <FormCheckbox
                      control={control}
                      name="postEvent"
                      label="Schedule Post Game"
                      disabled
                    />
                  </Grid>
                )}
                {!!liveStream?.data.postEventAt && (
                  <>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        timeSteps={{ minutes: timeInterval }}
                        name="postEventAt"
                        control={control}
                        label="Start Time"
                        disabled={true}
                      />
                    </Grid>
                    <Grid xl={3} md={12}>
                      <FormTimePicker
                        timeSteps={{ minutes: timeInterval }}
                        name="postEventEndAt"
                        control={control}
                        label="End Time"
                        disabled={true}
                      />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <StyledDiv>
              <Grid xs={12} md={12} xl={12} data-testid="liveSteam-tags">
                <Controller
                  name="icon"
                  control={control}
                  render={() => (
                    <Box>
                      <StyledFormLabel>
                        <Typography display="inline" variant="formLabel">
                          {"Tags"}
                        </Typography>
                      </StyledFormLabel>
                      <Grid
                        spacing={3}
                        sx={{ padding: "0px 0px 12px 0px", marginTop: "4px" }}
                      >
                        <Autocomplete
                          onFocus={(e) => e.target.blur()}
                          readOnly={true}
                          defaultValue={selectedTags}
                          freeSolo
                          disableClearable={true}
                          multiple
                          id="tags-outlined"
                          options={selectedTags}
                          renderOption={(props, option) => (
                            <li {...props}>
                              <Typography>{option.label}</Typography>
                            </li>
                          )}
                          filterSelectedOptions
                          renderInput={(params) => {
                            {
                              if (
                                Array.isArray(params.InputProps.startAdornment)
                              ) {
                                params.InputProps.startAdornment.splice(
                                  0,
                                  0,
                                  <InputAdornment position="start">
                                    <Search />
                                  </InputAdornment>
                                );
                              } else {
                                params.InputProps.startAdornment = (
                                  <InputAdornment position="start">
                                    <Search />
                                  </InputAdornment>
                                );
                              }
                              return <TextField {...params} />;
                            }
                          }}
                        />
                      </Grid>
                    </Box>
                  )}
                />
              </Grid>
              <Grid container xs={12} md={12} xl={12}>
                <Grid sx={{ padding: "12px" }} xl={12} md={12}>
                  <Controller
                    name="location"
                    rules={{ required: "Location is required" }}
                    control={control}
                    render={({ fieldState }) => (
                      <FormInputContainer>
                        <StyledFormLabel required>
                          <Typography display="inline" variant="formLabel">
                            {"Location"}
                          </Typography>
                        </StyledFormLabel>
                        <Autocomplete
                          disabled
                          options={
                            orgSportsLocations?.data?.locations?.map(
                              (location) => ({
                                value: location.locationId as string,
                                label: location.name as string,
                                location: location
                              })
                            ) || []
                          }
                          inputValue={sportLocationInputValue}
                          renderInput={(params) => {
                            return (
                              <SearchInput
                                data-testid="SEARCH_LOCATION_INPUT"
                                {...params}
                                {...fieldState}
                                value={sportLocationInputValue}
                              />
                            );
                          }}
                          renderOption={(props, option) => {
                            return (
                              <MenuItem
                                data-testId={`LOCATION_OPTION_${option.value}`}
                                key={option.value}
                                id={option.value}
                                selected={
                                  sportLocation
                                    ? option.value === sportLocation
                                    : option.value === undefined
                                }
                              ></MenuItem>
                            );
                          }}
                        />
                      </FormInputContainer>
                    )}
                  />

                  <Grid
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between"
                    }}
                  ></Grid>
                </Grid>
              </Grid>
            </StyledDiv>
            <Grid xs={12} md={6} xl={6} data-testid="liveSteam-notes">
              <FormInput
                control={control}
                name="notes"
                type="text"
                label="Notes"
                multiline={true}
                disabled
                rows={10}
              />
            </Grid>
          </Grid>
        </Form>
        {viewLive && (
          <LiveStreamPlayer
            handleViewLiveClose={handleViewLiveClose}
            liveStream={liveStream?.data}
          />
        )}
      </Container>
      <ConfirmationDialog
        open={!!LiveStreamToDelete}
        title="Delete Live Stream"
        body={`Are you sure you want to delete ${LiveStreamToDelete?.title}?`}
        close={() => setLiveStreamToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setLiveStreamToDelete(null)}
        isConfirming={isLoading}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Loader>
  );
};
