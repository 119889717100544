import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { FormCheckbox } from "@components/FormCheckbox";
import {
  FormLabel,
  InputAdornment,
  LinearProgress,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  ModelAdvertiser,
  ModelSponsorshipLevel,
  useSponsorshipLevelGet,
  useSponsorshipLevelIdGet,
  useSponsorshipLevelIdPut
} from "@sportsgravyengineering/sg-api-react-sdk";
import { Loader } from "@components/crud/Loader";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { Footer } from "@components/crud/Footer";
import { DevTool } from "@hookform/devtools";
import { capitalizeEveryWord } from "@utils/capitalize";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));
const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 12,
  borderRadius: 8,
  marginTop: "8px",
  width: "100%"
}));
const TableHeader = styled(Typography)(() => ({
  color: "#B3B3B3",
  fontSize: "13px",
  fontWeight: 400,
  fontFamily: "Inter"
}));
const TableValue = styled(Typography)(() => ({
  color: "#000",
  fontSize: "14px",
  fontWeight: 600,
  fontFamily: "Inter"
}));
export const SponsorshipLevelEdit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const organizationId = useRecoilValue(organizationAtom);
  const [sponsoredByAd, setSponsoredByAd] = useState(false);
  const [outStreamAd, setOutStreamAd] = useState(false);
  const [inStreamAd, setInStreamAd] = useState(false);
  const [feedPostAd, setFeedPostAd] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [adPlacementsInUse, setAdPlacementsInUse] = useState<string[]>([]);
  const [percentage, setPercentage] = useState({
    outStreamAd: { probability: 0 },
    sponsoredByAd: { probability: 0 },
    feedPostAd: { probability: 0 }
  });
  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    reset,
    setValue,
    getValues
  } = useForm({
    mode: "onBlur"
  });

  const { mutate: save, isLoading: isSaving } = useSponsorshipLevelIdPut();
  const saveHandler =
    (resetInsteadOfRoute = false) =>
    async (formValues) => {
      const values = {
        ...formValues,
        organizationId
      };
      const levels: ModelSponsorshipLevel[] = [];
      levels.push({
        name: values.name,
        isActive: values.isActive === "YES",
        amount: parseFloat(values.amount),
        sponsorshipId: id!,
        sponsoredByEnabled: sponsoredByAd,
        sponsoredByPercentage:
          (sponsoredByAd && Number(values.sponsoredByAd?.percentage) / 100) ||
          percentage.sponsoredByAd.probability / 100,
        ...(!organizationId && {
          feedPostEnabled: feedPostAd,
          feedPostPercentage:
            (feedPostAd && Number(values.feedPostAd?.percentage) / 100) ||
            percentage.feedPostAd.probability / 100
        }),
        inStreamEnabled: inStreamAd,
        outStreamEnabled: outStreamAd,
        outStreamPercentage:
          (outStreamAd && Number(values.outStreamAd?.percentage) / 100) ||
          percentage.outStreamAd.probability / 100,
        inStreamMaxDuration:
          inStreamAd && inStreamAd
            ? Number(values.instream_duration)
            : undefined,
        ...(organizationId && { organizationId })
      });
      Object.keys(values).forEach((key) => {
        if (key.includes("Ad") && typeof values[key] === "object") {
          const adType = key;
          Object.keys(values[adType]).forEach((subKey) => {
            if (subKey !== "percentage") {
              const sponsorshipId = subKey;
              const percentage = parseFloat(values[adType][subKey]) / 100;
              const sponsoredByPercentage =
                adType === "sponsoredByAd" ? percentage : 0;
              const feedPostPercentage =
                adType === "feedPostAd" ? percentage : 0;
              const outstreamPercentage =
                adType === "outStreamAd" ? percentage : 0;
              const existingEntry = levels.find(
                (entry) => entry.sponsorshipId === sponsorshipId
              );
              if (existingEntry) {
                existingEntry.sponsoredByPercentage += sponsoredByPercentage;
                existingEntry.outStreamPercentage += outstreamPercentage;
                if (!organizationId) {
                  existingEntry.feedPostPercentage += feedPostPercentage;
                  existingEntry.feedPostEnabled = !!(
                    existingEntry.feedPostPercentage &&
                    existingEntry.feedPostPercentage !== 0
                  );
                }
                existingEntry.sponsoredByEnabled =
                  existingEntry.sponsoredByPercentage != 0;
                existingEntry.outStreamEnabled =
                  existingEntry.outStreamPercentage != 0;
              } else {
                levels.push({
                  ...(organizationId && { organizationId: organizationId }),
                  sponsorshipId,
                  sponsoredByPercentage: sponsoredByPercentage,
                  outStreamPercentage: outstreamPercentage,
                  ...(!organizationId && {
                    feedPostEnabled: !!(
                      feedPostPercentage && feedPostPercentage !== 0
                    ),
                    feedPostPercentage: feedPostPercentage
                  }),
                  sponsoredByEnabled: sponsoredByPercentage != 0,
                  outStreamEnabled: outstreamPercentage != 0
                });
              }
            }
          });
        }
      });
      save(
        {
          sponsorshipId: id!,
          data: levels
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Sponsorship Level added successfully!", {
              variant: "success"
            });
            if (resetInsteadOfRoute) {
              reset();
            } else {
              navigate("/sponsorship-levels");
            }
          },
          onError: () => {
            enqueueSnackbar("Failed to add Sponsorship Level!", {
              variant: "error"
            });
          }
        }
      );
    };
  const { data: sponsorshipLevels, isLoading: sponsorshipLevelsLoading } =
    useSponsorshipLevelGet({ organizationId: organizationId! });

  const { data: sponsorshipLevel, isLoading: sponsorshipLevelLoading } =
    useSponsorshipLevelIdGet(id!);
  const getOptions = (max: number) => {
    const options: { label: string; value: string }[] = [];
    for (let i = 0; i <= 100 - max; i++) {
      options.push({
        label: `${i}%`,
        value: `${i}`
      });
    }
    return options;
  };
  const [nameCharacterCount, setNameCharacterCount] = useState(0);

  useEffect(() => {
    if (
      sponsorshipLevel?.data &&
      !sponsorshipLevelLoading &&
      sponsorshipLevels?.data &&
      !sponsorshipLevelsLoading
    ) {
      if (sponsorshipLevel.data.sponsorshipLevel.advertisers) {
        const advertisers = sponsorshipLevel.data.sponsorshipLevel.advertisers;
        const adPlacements = adPlacementsInUse;
        advertisers.map((advertiser: ModelAdvertiser) => {
          if (advertiser.advertisements) {
            advertiser.advertisements.map((ad) => {
              if (!adPlacementsInUse.includes(ad.placement!)) {
                adPlacements.push(ad.placement);
              }
            });
          }
        });
        setAdPlacementsInUse([...adPlacements]);
      }
      if (sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled) {
        const levels = sponsorshipLevel?.data.adPlacementPercentages
          ?.filter((level) => level.sponsorshipId != id)
          .filter((level) => level.sponsoredByEnabled)
          .map((level) => {
            setValue(
              `sponsoredByAd.${level.sponsorshipId!}`,
              String(level.sponsoredByPercentage * 100)
            );
          });
        if (levels.length == 0) {
          setValue(`sponsoredByAd.percentage`, String(100));
        }
        if (levels.length > 0) {
          setValue(
            "sponsoredByAd.percentage",
            String(
              Math.round(
                sponsorshipLevel.data.sponsorshipLevel?.sponsoredByPercentage *
                  100
              )
            )
          );
        }
        const updatedPercentage = sponsorshipLevel?.data
          ?.adPlacementPercentages!.filter(
            (level) => level.sponsorshipId !== id
          )
          .filter((level) => level.sponsoredByEnabled)
          .reduce((acc, curr) => {
            acc[curr.sponsorshipId!] = Math.round(
              curr.sponsoredByPercentage * 100
            );
            return acc;
          }, {});
        setPercentage((prevPercentage) => ({
          ...prevPercentage,
          sponsoredByAd: {
            ...prevPercentage.sponsoredByAd,
            ...updatedPercentage,
            probability: Math.round(
              sponsorshipLevel.data.sponsorshipLevel?.sponsoredByPercentage *
                100
            )
          }
        }));
      }
      if (sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled) {
        const levels = sponsorshipLevel?.data.adPlacementPercentages
          ?.filter((level) => level.sponsorshipId != id)
          .filter((level) => level.outStreamEnabled)
          .map((level) => {
            setValue(
              `outStreamAd.${level.sponsorshipId!}`,
              Math.round(String(level.outStreamPercentage * 100))
            );
          });
        if (levels.length == 0) {
          setValue(`outStreamAd.percentage`, String(100));
        }
        if (levels.length > 0) {
          setValue(
            "outStreamAd.percentage",
            String(
              Math.round(
                sponsorshipLevel.data.sponsorshipLevel?.outStreamPercentage *
                  100
              )
            )
          );
        }
        const updatedPercentage = sponsorshipLevel?.data
          ?.adPlacementPercentages!.filter(
            (level) => level.sponsorshipId !== id
          )
          .filter((level) => level.outStreamEnabled)
          .reduce((acc, curr) => {
            acc[curr.sponsorshipId!] = Math.round(
              curr.outStreamPercentage * 100
            );
            return acc;
          }, {});
        setPercentage((prevPercentage) => ({
          ...prevPercentage,
          outStreamAd: {
            ...prevPercentage.outStreamAd,
            ...updatedPercentage,
            probability: Math.round(
              sponsorshipLevel?.data?.sponsorshipLevel?.outStreamPercentage *
                100
            )
          }
        }));
      }

      if (
        !organizationId &&
        sponsorshipLevel?.data?.sponsorshipLevel?.feedPostEnabled &&
        sponsorshipLevel?.data?.sponsorshipLevel?.feedPostPercentage
      ) {
        const levels = sponsorshipLevel?.data.adPlacementPercentages
          ?.filter((level) => level.sponsorshipId != id)
          .filter((level) => level.feedPostEnabled)
          .map((level) => {
            setValue(
              `feedPostAd.${level.sponsorshipId!}`,
              String(level.feedPostPercentage * 100)
            );
          });
        if (levels.length == 0) {
          setValue(`feedPostAd.percentage`, String(100));
        }
        if (levels.length > 0) {
          setValue(
            "feedPostAd.percentage",
            String(
              Math.round(
                sponsorshipLevel.data.sponsorshipLevel?.feedPostPercentage * 100
              )
            )
          );
        }
        const updatedPercentage = sponsorshipLevel?.data
          ?.adPlacementPercentages!.filter(
            (level) => level.sponsorshipId !== id
          )
          .filter((level) => level.feedPostEnabled)
          .reduce((acc, curr) => {
            acc[curr.sponsorshipId!] = Math.round(
              curr.feedPostPercentage * 100
            );
            return acc;
          }, {});
        setPercentage((prevPercentage) => ({
          ...prevPercentage,
          feedPostAd: {
            ...prevPercentage.feedPostAd,
            ...updatedPercentage,
            probability: Math.round(
              sponsorshipLevel.data.sponsorshipLevel?.feedPostPercentage * 100
            )
          }
        }));
      }

      if (sponsorshipLevel?.data?.sponsorshipLevel?.inStreamEnabled) {
        setValue(
          "instream_duration",
          String(sponsorshipLevel?.data?.sponsorshipLevel?.inStreamMaxDuration)
        );
      }

      sponsorshipLevels.data.sponsorshipLevels.forEach((level) => {
        if (!sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled) {
          setValue(
            `outStreamAd.${level.sponsorshipId!}`,
            String(level.outStreamPercentage * 100)
          );
          setValue(`outStreamAd.percentage`, String(0));
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            outStreamAd: {
              ...prevPercentage.outStreamAd,
              [level.sponsorshipId!]: Math.round(
                level.outStreamPercentage * 100
              ),
              probability: 0
            }
          }));
        }
        if (!sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled) {
          setValue(
            `sponsoredByAd.${level.sponsorshipId!}`,
            String(level.sponsoredByPercentage * 100)
          );
          setValue(`sponsoredByAd.percentage`, String(0));
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            sponsoredByAd: {
              ...prevPercentage.sponsoredByAd,
              [level.sponsorshipId!]: Math.round(
                level.sponsoredByPercentage * 100
              ),
              probability: 0
            }
          }));
        }
        if (!sponsorshipLevel?.data?.sponsorshipLevel?.feedPostEnabled) {
          setValue(
            `feedPostAd.${level.sponsorshipId!}`,
            String(level.feedPostPercentage * 100)
          );
          setValue(`feedPost.percentage`, String(0));
          setPercentage((prevPercentage) => ({
            ...prevPercentage,
            feedPostAd: {
              ...prevPercentage.feedPostAd,
              [level.sponsorshipId!]: Math.round(
                level.feedPostPercentage * 100
              ),
              probability: 0
            }
          }));
        }
      });

      setSponsoredByAd(
        sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled || false
      );
      setOutStreamAd(
        sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled || false
      );
      setInStreamAd(
        sponsorshipLevel?.data?.sponsorshipLevel?.inStreamEnabled || false
      );
      setFeedPostAd(
        sponsorshipLevel?.data?.sponsorshipLevel?.feedPostEnabled || false
      );

      setValue("name", sponsorshipLevel?.data?.sponsorshipLevel?.name);
      setNameCharacterCount(
        sponsorshipLevel?.data?.sponsorshipLevel?.name?.length || 0
      );
      setValue(
        "isActive",
        sponsorshipLevel?.data?.sponsorshipLevel?.isActive ? "YES" : "NO"
      );
      setValue("amount", sponsorshipLevel?.data?.sponsorshipLevel?.amount);
      setValue(
        "inStreamAdEnabled",
        sponsorshipLevel?.data?.sponsorshipLevel?.inStreamEnabled
      );
      setValue(
        "outStreamAdEnabled",
        sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled
      );
      setValue(
        "sponsoredByAdEnabled",
        sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled
      );
      setValue(
        "feedPostAdEnabled",
        !!sponsorshipLevel?.data.sponsorshipLevel?.feedPostEnabled
      );
    }
  }, [sponsorshipLevel, sponsorshipLevelLoading, sponsorshipLevels]);
  const renderSub = (Adtype: string, levels) => {
    const percentRemaining =
      100 -
      Object.values(percentage[Adtype] as Record<string, number>).reduce(
        (accumulator, currentValue) =>
          accumulator + (typeof currentValue === "number" ? currentValue : 0),
        0
      );
    return (
      <>
        <Grid sx={{ paddingLeft: "12px" }} xs={12} md={6} sm={12} spacing={3}>
          <FormSelect
            label="Probability Percentage of being Displayed"
            name={`${Adtype}.percentage`}
            disabled={levels.length == 0}
            required={true}
            options={getOptions(0)}
            control={control}
            onChange={(e) => {
              const updatedPercentage = {
                ...levels.reduce((acc, curr) => {
                  (acc[curr.sponsorshipId!] = 0), 10;
                  return acc;
                }, {})
              };
              setPercentage((prevPercentage) => ({
                ...prevPercentage,
                [Adtype]: {
                  probability: parseInt(e.target.value, 10),
                  ...updatedPercentage
                }
              }));
              levels.map((level) => {
                setValue(`${Adtype}.${level.sponsorshipId}`, 0);
              });
            }}
            rules={{ required: "Probability Percentage is required" }}
          />
        </Grid>
        {levels.length >= 1 && (
          <>
            <Grid
              container
              spacing={3}
              xs={12}
              md={6}
              marginLeft="5px"
              sx={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#F4F4F4"
              }}
            >
              <>
                {levels &&
                  levels.map((level, index) => (
                    <Grid key={level.sponsorshipId} xl={4} md={4} sm={12}>
                      <FormSelect
                        label={level.name}
                        name={`${Adtype}.${level.sponsorshipId!}`}
                        options={
                          index > 0
                            ? getOptions(
                                Object.keys(percentage[Adtype])
                                  .map((key, i) =>
                                    index >= i ? percentage[Adtype][key] : null
                                  )
                                  .filter((value) => value !== null)
                                  .reduce((a, b) => a + b, 0)
                              )
                            : getOptions(getValues(`${Adtype}.percentage`))
                        }
                        control={control}
                        onChange={(e) => {
                          const updatedPercentage = {
                            ...percentage[Adtype],
                            ...levels.reduce((acc, curr, i) => {
                              if (i > index) {
                                (acc[curr.sponsorshipId!] = 0), 10;
                              }
                              if (i == index) {
                                (acc[curr.sponsorshipId!] = parseInt(
                                  e.target.value,
                                  10
                                )),
                                  10;
                              }
                              return acc;
                            }, {})
                          };
                          setPercentage((prevPercentage) => ({
                            ...prevPercentage,
                            [Adtype]: updatedPercentage
                          }));

                          for (let i = index; i < levels.length; i++) {
                            setValue(
                              `${Adtype}.${levels[i].sponsorshipId!}`,
                              0
                            );
                          }
                        }}
                      />
                    </Grid>
                  ))}
              </>
            </Grid>
            <Container
              sx={{
                padding: "12px !important",
                display: "flex",
                flexDirection: "column"
              }}
            >
              <Grid container justifyContent="space-between">
                <Grid>
                  <Typography
                    data-testid="import-player-overview-completed-status"
                    sx={{
                      color: "#7A7F89",
                      fontWeight: 500,
                      fontSize: "14px"
                    }}
                  >
                    {Adtype == "outStreamAd"
                      ? "Out-Stream"
                      : Adtype == "inStreamAd"
                      ? "In-Stream"
                      : "Sponsored By"}{" "}
                    Ad fields must add up to 100%
                  </Typography>
                </Grid>
                <Grid>
                  <TableHeader sx={{ display: "inline" }}>
                    Percentage Remaining:
                  </TableHeader>
                  <TableValue sx={{ display: "inline" }}>
                    {percentRemaining}%
                  </TableValue>
                </Grid>
              </Grid>
              <BorderLinearProgress
                className={`${percentRemaining == 0 ? "success" : "fail"}`}
                variant="determinate"
                color={percentRemaining == 0 ? "success" : "error"}
                value={100 - percentRemaining}
              />
            </Container>
          </>
        )}
        {levels && levels.length == 0 && (
          <Grid
            spacing={3}
            xs={12}
            md={6}
            data-testid="sponsorship-level-note"
            sx={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F4F4F4",
              height: "fit-content",
              marginTop: "28px"
            }}
          >
            <Typography>
              Note: Because this ad placement is only included in one
              sponsorship level it has been automatically set to be displayed
              100% of the time.
            </Typography>
          </Grid>
        )}
      </>
    );
  };
  const checkSaveDisabled = () => {
    const sum = Object.values(percentage)
      .flatMap((adType) =>
        Object.values(adType).filter((value) => typeof value === "number")
      )
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    return (
      sum === 0 ||
      sum % 100 !== 0 ||
      (!inStreamAd && !outStreamAd && !sponsoredByAd && !feedPostAd)
    );
  };

  return (
    <Loader isLoading={sponsorshipLevelLoading || sponsorshipLevelsLoading}>
      <Container>
        <Toolbar title="Edit Sponsorship Level" />
        <Form>
          <Grid data-testid="sponsorship-level-form" container spacing={2}>
            <Grid container direction="row" spacing="24px" md={12}>
              <Grid container direction="column" xs={6} spacing="1px">
                <Grid data-testid="sponsorship-level-name">
                  <FormInput
                    control={control}
                    name="name"
                    type="text"
                    label="Name"
                    required={true}
                    onChange={(e) => {
                      setValue("name", capitalizeEveryWord(e.target.value));
                      setNameCharacterCount(e.target?.value?.length || 0);
                    }}
                    rules={{
                      required: "Name is required",
                      maxLength: {
                        value: 10,
                        message: "Name must not exceed 10 characters"
                      }
                    }}
                  />
                </Grid>
                <Grid style={{ textAlign: "end" }}>
                  <Typography>
                    {"Character Count Remaining: " + (10 - nameCharacterCount)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={6}>
                <FormInput
                  type="number"
                  control={control}
                  label="Amount"
                  name="amount"
                  required
                  rules={{
                    required: "Amount is required"
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">{"$"}</InputAdornment>
                    ),
                    step: "0.1"
                  }}
                />
              </Grid>
            </Grid>
            <Grid data-testid="sponsorship-level-isActive" xs={6}>
              <FormSelect
                control={control}
                disabled={
                  (sponsorshipLevel?.data?.sponsorshipLevel?.advertisers
                    ?.length || 0) > 0 || false
                }
                options={[
                  {
                    label: "Yes",
                    value: "YES"
                  },
                  {
                    label: "No",
                    value: "NO"
                  }
                ]}
                name="isActive"
                label="Is Active"
              />
            </Grid>
            <Grid xs={12}>
              <StyledFormLabel required={true}>
                <Typography
                  style={{ fontWeight: "bold", fontSize: "13px" }}
                  display="inline"
                  variant="formLabel"
                >
                  Ad Placements
                </Typography>
              </StyledFormLabel>
              {!organizationId && (
                <Grid
                  container
                  xs={12}
                  md={12}
                  sm={12}
                  xl={12}
                  sx={{ alignItems: "center" }}
                >
                  <FormCheckbox
                    label="Feed Post Ad - Feed/Recruiting Feed Posts "
                    name="feedPostAdEnabled"
                    control={control}
                    disabled={
                      adPlacementsInUse.includes("FEED_POST") &&
                      sponsorshipLevel?.data?.sponsorshipLevel?.feedPostEnabled
                    }
                    onChange={(e) => {
                      setFeedPostAd(e.target.checked);
                      const level =
                        sponsorshipLevel?.data.adPlacementPercentages
                          ?.filter((level) => level.sponsorshipId != id)
                          .filter((level) => level.feedPostEnabled);
                      if (level.length == 0) {
                        setValue(`feedPostAd.percentage`, String(100));
                      }
                    }}
                  />
                  {feedPostAd && (
                    <>
                      {renderSub(
                        "feedPostAd",
                        sponsorshipLevel?.data.adPlacementPercentages
                          ?.filter((level) => level.sponsorshipId != id)
                          .filter((level) => level.feedPostEnabled)
                      )}
                    </>
                  )}
                </Grid>
              )}
              <Grid
                container
                xs={12}
                md={12}
                sm={12}
                xl={12}
                sx={{ alignItems: "center" }}
              >
                <FormCheckbox
                  label="Sponsored By Ad - Live Stream Event Feed Posts"
                  name="sponsoredByAdEnabled"
                  control={control}
                  disabled={
                    adPlacementsInUse.includes("SPONSORED_BY") &&
                    sponsorshipLevel?.data?.sponsorshipLevel?.sponsoredByEnabled
                  }
                  onChange={(e) => {
                    setSponsoredByAd(e.target.checked);
                    const level = sponsorshipLevel?.data.adPlacementPercentages
                      ?.filter((level) => level.sponsorshipId != id)
                      .filter((level) => level.sponsoredByEnabled);
                    if (level.length == 0) {
                      setValue(`sponsoredByAd.percentage`, String(100));
                    }
                  }}
                />
                {sponsoredByAd && (
                  <>
                    {renderSub(
                      "sponsoredByAd",
                      sponsorshipLevel?.data.adPlacementPercentages
                        ?.filter((level) => level.sponsorshipId != id)
                        .filter((level) => level.sponsoredByEnabled)
                    )}
                  </>
                )}
              </Grid>
              <Grid container xs={12} md={12} sm={12} xl={12}>
                <FormCheckbox
                  label="Out-Stream Ad - View Live Stream Screen"
                  name="outStreamAdEnabled"
                  control={control}
                  disabled={
                    adPlacementsInUse.includes("OUTSTREAM") &&
                    sponsorshipLevel?.data?.sponsorshipLevel?.outStreamEnabled
                  }
                  onChange={(e) => {
                    setOutStreamAd(e.target.checked);
                    const level = sponsorshipLevel?.data.adPlacementPercentages
                      ?.filter((level) => level.sponsorshipId != id)
                      .filter((level) => level.outStreamEnabled);
                    if (level.length == 0) {
                      setValue(`outStreamAd.percentage`, String(100));
                    }
                  }}
                />
                {outStreamAd && (
                  <>
                    {renderSub(
                      "outStreamAd",
                      sponsorshipLevel?.data.adPlacementPercentages
                        ?.filter((level) => level.sponsorshipId != id)
                        .filter((level) => level.outStreamEnabled)
                    )}
                  </>
                )}
              </Grid>

              <FormCheckbox
                label="In-Stream Ad - Live Stream Intermissions"
                name="inStreamAdEnabled"
                control={control}
                disabled={
                  adPlacementsInUse.includes("INSTREAM") &&
                  sponsorshipLevel?.data?.sponsorshipLevel?.inStreamEnabled
                }
                onChange={(e) => {
                  setInStreamAd(e.target.checked);
                }}
              />

              {inStreamAd && (
                <>
                  <Grid
                    container
                    direction="row"
                    spacing="20px"
                    marginLeft="5px"
                    xs={12}
                    md={12}
                    sm={12}
                    xl={12}
                    alignItems="center"
                  >
                    <Grid md={6} xs={6}>
                      <FormSelect
                        label="Max Duration of In-Stream Video"
                        name="instream_duration"
                        required={inStreamAd}
                        options={[
                          {
                            label: "6 seconds",
                            value: "6"
                          },
                          {
                            label: "15 seconds",
                            value: "15"
                          },
                          {
                            label: "30 seconds",
                            value: "30"
                          }
                        ]}
                        control={control}
                      />
                    </Grid>
                    <Grid
                      spacing={3}
                      md={6}
                      data-testid="instream-level-note"
                      sx={{
                        display: "flex",
                        alignItems: "center",

                        backgroundColor: "#F4F4F4",
                        height: "fit-content",
                        marginTop: "28px"
                      }}
                    >
                      <Typography>
                        Note: During live stream intermissions, sponsorship
                        levels supporting this stream are shown in order of the
                        sort order your organization has set. Advertisements
                        within the Higher Sponsorship levels are shown first,
                        then everyone gets a turn!
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <DevTool control={control} />
        </Form>
        <Footer
          cancelBtnClick={() => setOpenCancelDialog(true)}
          saveBtnClick={handleSubmit(saveHandler(false))}
          isDisabled={!isValid || isSaving || checkSaveDisabled() || !isDirty}
          isLoading={isSaving}
        />
        <ConfirmationDialog
          title="Are you sure you want to cancel?"
          body="All of your current changes will be lost."
          open={openCancelDialog}
          close={() => setOpenCancelDialog(false)}
          onCancel={() => setOpenCancelDialog(false)}
          onConfirm={() => navigate("/sponsorship-levels")}
          cancelBtnText="Cancel"
          confirmBtnText="Confirm"
        />
      </Container>
    </Loader>
  );
};
