import {
  Backdrop,
  Box,
  Container,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormControl
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ReactPlayer from "react-player";
import { useState } from "react";

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  marginLeft: "125px",
  width: "100%",
  [theme.breakpoints.down("xl")]: {
    marginLeft: "0%"
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: "0px"
  }
}));
const StyledBoxHeader = styled(Box)({
  height: "64px",
  backgroundColor: "#4F46E5",
  borderRadius: "16px 16px 0px 0px"
});
const StyledReactPlayer = styled(ReactPlayer)(() => ({
  backgroundColor: "#E5E5E5 !important",
  "& video": {
    objectFit: "contain"
  }
}));
export const SponsorshipLevelExample = (props: {
  title;
  onClose;
  src;
  type?;
  email?;
  radioOptionsMap?;
  defaultSelectedOption?;
}) => {
  console.log(props.email);

  const [selectedOption, setSelectionOption] = useState<string>(
    props.defaultSelectedOption
  );

  return (
    <Backdrop
      sx={{
        overflow: "hidden",
        overflowY: "none",
        color: "#fff",
        zIndex: (theme) => theme.zIndex.drawer + 1
      }}
      open={true}
    >
      <Container>
        <StyledBox
          data-testid="sponsorshiplevel-example"
          style={{ ...(props.email && { marginLeft: "0px" }) }}
        >
          <StyledBoxHeader>
            <Grid
              container
              style={{
                padding: "18px 20px 20px 32px",
                justifyContent: "space-between",
                alignItems: "center"
              }}
            >
              <Grid sx={{ maxWidth: "88%" }}>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "#fff",
                    fontStyle: "normal",
                    lineHeight: "20px",
                    fontWeight: "600"
                  }}
                >
                  {props.title}
                </Typography>
              </Grid>
              <Grid>
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={props.onClose}
                />
              </Grid>
            </Grid>
          </StyledBoxHeader>
          {!props.radioOptionsMap ? (
            props.type === "video" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  height: "500px"
                }}
              >
                <StyledReactPlayer
                  playing={true}
                  height={"70%"}
                  width={"70%"}
                  url={props.src}
                />
              </Box>
            ) : (
              <Box sx={{ textAlign: "center", marginTop: "5px" }}>
                <img src={props.src} alt="" />
              </Box>
            )
          ) : props.type === "video" ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                height: "500px"
              }}
            >
              <StyledReactPlayer
                playing={true}
                height={"75%"}
                width={"75%"}
                url={props.src}
              />
            </Box>
          ) : (
            <Grid container direction="row" padding="0px 54px 0px 40px">
              <Grid
                container
                item
                xs={6}
                direction="column"
                marginTop="20px"
                spacing="10px"
              >
                <Grid item>
                  <Typography
                    variant="h3"
                    style={{
                      color: "#1E293B",
                      fontSize: "24px",
                      fontWeight: 400
                    }}
                  >
                    Ad Layout Options
                  </Typography>
                </Grid>
                <Grid item>
                  <FormControl>
                    <RadioGroup
                      value={selectedOption}
                      onChange={(e) => {
                        setSelectionOption(e.target.value);
                      }}
                    >
                      {props.radioOptionsMap.map((radioOption) => (
                        <FormControlLabel
                          key={radioOption.name}
                          value={radioOption.name}
                          control={<Radio />}
                          label={radioOption.name}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={6} textAlign="center" marginTop="15px">
                <img
                  src={
                    props.radioOptionsMap?.find(
                      (radio) => radio.name === selectedOption
                    )?.src
                  }
                  alt=""
                />
              </Grid>
            </Grid>
          )}
        </StyledBox>
      </Container>
    </Backdrop>
  );
};
