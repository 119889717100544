/* eslint-disable @typescript-eslint/no-explicit-any */
import { Avatar } from "@files-ui/react";
import {
  Box,
  FormHelperText,
  FormLabel,
  Grid,
  InputProps,
  TextField,
  TextFieldProps,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ModelCountry } from "@sportsgravyengineering/sg-api-react-sdk";
import { capitalizeEveryWord } from "@utils/capitalize";
import { omitProperties } from "@utils/objectFunctions";
import Compressor from "compressorjs";
import { MuiFileInput } from "mui-file-input";
import MuiPhoneNumber from "mui-phone-number";
import { ChangeEvent } from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";

const FormInputContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const StyledFileInput = styled(MuiFileInput)(() => ({
  "& .MuiInputAdornment-positionEnd": {
    flexGrow: 1,
    justifyContent: "end"
  }
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

export const FormInput = (props: {
  name: string;
  control: Control<any, any>;
  country?: ModelCountry | undefined;
  rules: any | undefined;
  label: string;
  type: string | undefined;
  required: boolean | undefined;
  onChange?: (
    event: ChangeEvent | File | File[] | null,
    field?: ControllerRenderProps<any, string>
  ) => void;
  onBlur?: () => void;
  InputProps?: InputProps | any;
  TextProps?: TextFieldProps;
  disabled?: boolean;
  rows?: number;
  multiline?: boolean;
  capitalize?: boolean;
  capitalizeWords?: boolean;
  sideMessage?: string;
  enteredValue?: string;
}) => {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      render={({ fieldState, field }) => (
        <FormInputContainer>
          {props.sideMessage ? (
            <Grid item container direction="row" spacing="24px">
              <Grid item>
                <StyledFormLabel required={props.required}>
                  <Typography display="inline" variant="formLabel">
                    {props.label}
                  </Typography>
                </StyledFormLabel>
              </Grid>
              <Grid item>
                <Typography>{props.sideMessage}</Typography>
              </Grid>
            </Grid>
          ) : (
            <StyledFormLabel required={props.required}>
              <Typography display="inline" variant="formLabel">
                {props.label}
              </Typography>
            </StyledFormLabel>
          )}
          {props.type === "file" ? (
            <StyledFileInput
              {...omitProperties(field, ["ref"])}
              placeholder=""
              error={!!fieldState.error}
              value={field.value || ""}
              onBlur={field.onBlur}
              helperText={fieldState.error?.message}
              inputProps={props.InputProps}
              disabled={props.disabled}
              // @ts-ignore
              onChange={(event) => {
                if (props.onChange) props.onChange(event, field);
              }}
              getInputText={(value) =>
                Array.isArray(value)
                  ? value.map((v) => v.name).join(", ")
                  : value instanceof File
                  ? value?.name
                  : "Click to Upload File"
              }
              {...(props.TextProps || {})}
            />
          ) : props.type === "avatar" ? (
            <>
              <Avatar
                emptyLabel={"No avatar selected"}
                changeLabel={"Change avatar"}
                onChange={async (file) => {
                  const compressedFile: File = await new Promise(
                    (resolve, reject) => {
                      new Compressor(file, {
                        mimeType: "image/png",
                        width: 200,
                        height: 200,
                        resize: "cover",
                        convertSize: Infinity,
                        success(file) {
                          return resolve(file as File);
                        },
                        error(err) {
                          return reject(err);
                        }
                      });
                    }
                  );

                  const dataUrl = await new Promise<string>(
                    (resolve, reject) => {
                      const reader = new FileReader();
                      reader.onload = () => resolve(reader.result as string);
                      reader.onerror = () => reject(reader.error);
                      reader.onabort = () => reject(new Error("Read aborted"));
                      reader.readAsDataURL(compressedFile);
                    }
                  );

                  field.onChange(dataUrl);
                  if (props.onChange) {
                    props.onChange(null, field);
                  }
                }}
                src={field.value || ""}
                {...{
                  readOnly: props.disabled
                }}
              />
              <FormHelperText error={!!fieldState.error}>
                {fieldState.error?.message}
              </FormHelperText>
            </>
          ) : props.type === "tel" ? (
            <MuiPhoneNumber
              placeholder=""
              error={!!fieldState.error}
              type={"text"}
              value={field.value || ""}
              // @ts-ignore
              onChange={(value) => {
                value = value.replace(/[^+0-9]/g, "");
                field.onChange(value);
                if (props.onChange) props.onChange(null, field);
              }}
              defaultCountry={
                props.country?.countryId
                  ? props.country.countryId.toLowerCase()
                  : "us"
              }
              disableDropdown={!!props.country || props.disabled}
              countryCodeEditable={false}
              onBlur={field.onBlur}
              helperText={fieldState.error?.message}
              InputProps={{
                ...(props.InputProps || {})
              }}
              disabled={props.disabled}
              multiline={props.multiline}
              variant="outlined"
              {...(props.TextProps || {})}
            />
          ) : (
            <TextField
              placeholder=""
              data-testid={"FORM_INPUT_" + props.name}
              error={!!fieldState.error}
              type={props.type}
              rows={props.rows}
              value={props.enteredValue || field.value?.toString() || ""}
              onChange={(event) => {
                if (props.capitalize)
                  event.target.value = event.target.value.toUpperCase();
                if (props.capitalizeWords)
                  event.target.value = capitalizeEveryWord(event.target.value);
                field.onChange(event);
                if (props.onChange) props.onChange(event, field);
              }}
              onBlur={() => {
                field.onBlur();
                if (props.onBlur) props.onBlur();
              }}
              helperText={fieldState.error?.message}
              InputProps={props.InputProps}
              disabled={props.disabled}
              multiline={props.multiline}
              {...(props.TextProps || {})}
            />
          )}
        </FormInputContainer>
      )}
    />
  );
};

FormInput.defaultProps = {
  rules: undefined,
  required: false,
  disabled: false,
  multiline: false
};
