import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import { organizationAtom } from "@recoil/auth";
import { hasPermission } from "@services/Casbin";
import {
  useCannedMessageMessageIdGet,
  cannedMessageWrapperGet,
  Gender,
  useAdminSportGet,
  ModelCannedMessage,
  useCannedMessageMessageIdDelete
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilValue } from "recoil";

export const CannedMessageView = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [cannedMessageToDelete, setCannedMessageToDelete] =
    useState<ModelCannedMessage | null>(null);
  const [permissions, setPermissions] = useState({
    delete: false,
    edit: false
  });

  const organizationId = useRecoilValue(organizationAtom);
  const { messageId } = useParams();
  const [positionOptions, setPositionOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [conceptOptions, setConceptOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [levelsOptions, setLevelsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [skillsOptions, setSkillsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [systemsOptions, setSystemsOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [genderOptions] = useState<{ label: string; value: string }[]>([
    { label: Gender.FEMALE, value: Gender.FEMALE },
    { label: Gender.MALE, value: Gender.MALE }
  ]);

  const {
    data: cannedMessage,
    isFetching: isMessageFetching,
    error: error
  } = useCannedMessageMessageIdGet(messageId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId
      })) || [],
    [sports]
  );

  useEffect(() => {
    if (!cannedMessage?.data?.sportId) {
      return;
    }
    loadOptions(cannedMessage?.data?.sportId);
  }, [cannedMessage]);

  const loadOptions = async (inputValue) => {
    const data = await cannedMessageWrapperGet({
      sportId: inputValue as string
    });

    setPositionOptions([
      ...(data.data.positions?.map((position) => ({
        label: position.name!,
        value: position.positionId!
      })) || [])
    ]);
    setConceptOptions([
      ...(data.data.concepts?.map((concepts) => ({
        label: concepts.name!,
        value: concepts.conceptId!
      })) || [])
    ]);
    setLevelsOptions([
      ...(data.data.levels?.map((levels) => ({
        label: levels.name!,
        value: levels.levelId!
      })) || [])
    ]);
    setSkillsOptions([
      ...(data.data.skills?.map((skills) => ({
        label: skills.name!,
        value: skills.skillId!
      })) || [])
    ]);
    setSystemsOptions([
      ...(data.data.systems?.map((systems) => ({
        label: systems.name!,
        value: systems.systemId!
      })) || [])
    ]);
  };

  const { control, reset } = useForm();
  useEffect(
    () =>
      reset(
        {
          title: cannedMessage?.data?.title,
          message: cannedMessage?.data?.message,
          genders: cannedMessage?.data?.genders,
          sportId: cannedMessage?.data?.sportId,
          positions: cannedMessage?.data?.positions?.map(
            (position) => position.positionId
          ),
          levels: cannedMessage?.data?.levels?.map((level) => level.levelId),
          concepts: cannedMessage?.data?.concepts?.map(
            (concept) => concept.conceptId
          ),
          skills: cannedMessage?.data?.skills?.map((skill) => skill.skillId),
          systems: cannedMessage?.data?.systems?.map(
            (system) => system.systemId
          )
        },
        {
          keepDefaultValues: true
        }
      ),
    [
      cannedMessage,
      positionOptions,
      sportOptions,
      conceptOptions,
      skillsOptions,
      levelsOptions,
      systemsOptions
    ]
  );
  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useCannedMessageMessageIdDelete();

  const onConfirmDelete = async () => {
    if (!cannedMessageToDelete?.cannedMessageId) return;
    try {
      await deleteAsync({ messageId: cannedMessageToDelete.cannedMessageId });
      enqueueSnackbar("Canned Message deleted successfully", {
        variant: "success"
      });
      setCannedMessageToDelete(null);
      navigate("/canned-messages");
    } catch (error) {
      enqueueSnackbar(
        "Something went wrong! Unable to delete canned message.",
        {
          variant: "error"
        }
      );
      setCannedMessageToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("feed.canned-messaged", "DELETE");
      const edit = await checkPermission("feed.canned-messaged", "EDIT");
      setPermissions({
        delete: del,
        edit
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Canned Message"
        backBtnClick={() => navigate("/canned-messages")}
        {...(permissions.edit && {
          editBtnClick: () => navigate(`/canned-messages/${messageId}/edit`)
        })}
        {...(permissions.delete && {
          deleteBtnClick: () => setCannedMessageToDelete(cannedMessage!.data)
        })}
      />
      <Form>
        <Loader isLoading={isSportLoading || isMessageFetching}>
          <Grid container spacing={3}>
            <Grid xs={12} md={12} data-testid="VIEW_CANNED_MESSAGE">
              <FormInput
                control={control}
                name="message"
                type="text"
                label="Message"
                required={true}
                disabled={true}
                rules={{
                  required: "Message is required"
                }}
              />
            </Grid>
            <Grid xs={12} md={12} data-testid="VIEW_CANNED_TITLE">
              <FormInput
                control={control}
                name="title"
                type="text"
                label="Title"
                required={true}
                disabled={true}
                rules={{
                  required: "Title is required"
                }}
              />
            </Grid>
            <Grid container xs={12} md={12}>
              <Grid xs={12} md={6} data-testid="VIEW_CANNED_SPORT">
                <FormSelect
                  control={control}
                  name="sportId"
                  label="Sport"
                  required={true}
                  options={sportOptions}
                  disabled={true}
                  rules={{
                    required: "Sport is required"
                  }}
                />
              </Grid>
            </Grid>

            <Grid xs={12} md={6} data-testid="VIEW_CANNED_GENDER">
              <FormMultiSelect
                control={control}
                name="genders"
                label="Genders"
                required={false}
                options={genderOptions}
                disabled={true}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="VIEW_CANNED_LEVEL">
              <FormMultiSelect
                control={control}
                name="levels"
                label="Levels"
                required={false}
                disabled={true}
                options={levelsOptions}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="VIEW_CANNED_POSITIONS">
              <FormMultiSelect
                control={control}
                name="positions"
                label="Positions"
                required={false}
                disabled={true}
                options={positionOptions}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="VIEW_CANNED_SKILLS">
              <FormMultiSelect
                control={control}
                name="skills"
                label="Skills"
                required={false}
                disabled={true}
                options={skillsOptions}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="VIEW_CANNED_CONCEPTS">
              <FormMultiSelect
                control={control}
                name="concepts"
                label="Concepts"
                required={false}
                disabled={true}
                options={conceptOptions}
              />
            </Grid>
            <Grid xs={12} md={6} data-testid="VIEW_CANNED_SYSTEMS">
              <FormMultiSelect
                control={control}
                name="systems"
                label="Systems"
                required={false}
                disabled={true}
                options={systemsOptions}
              />
            </Grid>
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!cannedMessageToDelete}
        title="Delete Canned Message?"
        body={`Are you sure you want to delete ${cannedMessageToDelete?.title}?`}
        close={() => setCannedMessageToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setCannedMessageToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
