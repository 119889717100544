/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInput } from "@components/FormInput";
import { FormSelect } from "@components/FormSelect";
import { Grid } from "@mui/material";
import { GENDERS } from "@utils/constants";
import { Control, FieldValues, UseFormReturn } from "react-hook-form";
import { PlayerOption } from "../components/PlayerSelectionForm";
import { useEffect } from "react";
import { capitalizeEveryWord } from "@utils/capitalize";

type SelectOption = {
  label: string;
  value?: string;
};
export const TeamDetailsForm = (props: {
  disabled: undefined | boolean;
  control: Control<any, any>;
  form: UseFormReturn<FieldValues, any, undefined>;
  setSportsId: (value: string | undefined) => void;
  setSeasonId: (value: string | undefined) => void;
  isEditing: undefined | boolean;
  isLoadingOptions: undefined | boolean;
  sportOptions: SelectOption[];
  seasonOptions: SelectOption[];
  levelOptions: SelectOption[];
  playersWithValues?: PlayerOption[];
}) => {
  const { setValue } = props.form;

  useEffect(() => {
    if (props.sportOptions.length === 1) {
      setValue("sportId", props.sportOptions[0].value);
      props.setSportsId(props.sportOptions[0].value);
    }
  }, [props.sportOptions]);
  return (
    <Grid
      container
      direction="column"
      spacing="25px"
      data-testid="TEAM_DETAILS_CONTAINER"
    >
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6} data-testid="TEAM_DETAILS_NAME">
          <FormInput
            name="name"
            control={props.control}
            rules={{ required: "Name is required" }}
            label="Name"
            type="text"
            required={true}
            onChange={(e) => {
              setValue("name", capitalizeEveryWord(e.target.value));
            }}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={6} data-testid="TEAM_DETAILS_SPORT">
          <FormSelect
            control={props.control}
            name="sportId"
            label="Sport"
            disabled={
              props.disabled ||
              (props.isEditing && (props.playersWithValues?.length || 0) > 0) ||
              props.sportOptions.length === 1
            }
            required={true}
            isLoading={props.isLoadingOptions}
            options={props.sportOptions}
            onChange={(e) => {
              setValue("season", undefined);
              setValue("gender", "");
              setValue("level", "");
              props.setSportsId(e.target.value);
            }}
            rules={{
              required: "Sport is required"
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6} data-testid="TEAM_DETAILS_SEASON">
          <FormSelect
            control={props.control}
            name="season"
            label="Season"
            required={true}
            disabled={props.disabled || props.isEditing}
            isLoading={props.isLoadingOptions}
            options={props.seasonOptions}
            rules={{
              required: "Season is required"
            }}
            onChange={(e) => {
              props.setSeasonId(e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} data-testid="TEAM_DETAILS_GENDER">
          <FormSelect
            control={props.control}
            name="gender"
            label="Gender"
            disabled={props.disabled || props.isEditing}
            required={true}
            options={GENDERS}
            rules={{
              required: "Gender is required"
            }}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6} data-testid="TEAM_DETAILS_LEVEL">
          <FormSelect
            control={props.control}
            name="level"
            label="Level"
            required={true}
            disabled={props.disabled || props.isEditing}
            isLoading={props.isLoadingOptions}
            options={props.levelOptions}
            rules={{
              required: "Level is required"
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
