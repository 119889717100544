/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { ProgramDateAndTimeManager } from "@components/ProgramDateAndTimeManager";
import { Grid } from "@mui/material";
import { capitalizeEveryWord } from "@utils/capitalize";
import { GENDERS } from "@utils/constants";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";

type SelectOption = {
  label: string;
  value?: string;
};
export const ProgramDetailsForm = (props: {
  disabled: undefined | boolean;
  form: UseFormReturn<any, any, undefined>;
  setSportsId: (value: string | undefined) => void;
  isEditing: undefined | boolean;
  isLoadingOptions: undefined | boolean;
  sportOptions: SelectOption[];
  levelOptions: SelectOption[];
  typeOptions: SelectOption[];
  isEditMode?: undefined | boolean;
  generatedComponents;
}) => {
  const { control, setValue } = props.form;
  useEffect(() => {
    if (props.sportOptions.length === 1) {
      setValue("sportId", props.sportOptions[0].value);

      props.setSportsId(props.sportOptions[0].value);
    }
  }, [props.sportOptions]);
  console.log("IMAZ GC::", props.generatedComponents);
  return (
    <Grid
      container
      direction="column"
      spacing="25px"
      padding={1}
      data-testid="PROGRAM_DETAILS_CONTAINER"
    >
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6} data-testid="PROGRAM_DETAILS_NAME">
          <FormInput
            name="name"
            control={control}
            rules={{ required: "Name is required" }}
            label="Name"
            onChange={(e) => {
              setValue("name", capitalizeEveryWord(e.target.value));
            }}
            type="text"
            required={true}
            disabled={props.disabled}
          />
        </Grid>
        <Grid item xs={6} data-testid="PROGRAM_DETAILS_TYPE">
          <FormSelect
            name="type"
            rules={{ required: "Type is required" }}
            control={control}
            label="Type"
            options={props.typeOptions}
            disabled={props.disabled}
            required
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction="row"
        spacing="24px"
        data-testid="PROGRAM_DETAILS_SPORT"
      >
        <Grid item xs={6}>
          <FormSelect
            control={control}
            name="sportId"
            label="Sport"
            required={true}
            disabled={
              props.disabled ||
              props.isEditMode ||
              props.sportOptions.length === 1
            }
            isLoading={props.isLoadingOptions}
            options={props.sportOptions}
            onChange={(e) => {
              setValue("gender", "");
              setValue("level", "");
              props.setSportsId(e.target.value);
            }}
            rules={{
              required: "Sport is required"
            }}
          />
        </Grid>
        <Grid item xs={6} data-testid="PROGRAM_DETAILS_GENDER">
          <FormMultiSelect
            name="gender"
            value="gender"
            control={control}
            label="Gender"
            disabled={props.disabled || props.isEditMode}
            options={GENDERS}
            onChange={() => {}}
            onRemove={() => {}}
          />
        </Grid>
      </Grid>
      <Grid item container direction="row" spacing="24px">
        <Grid item xs={6} data-testid="PROGRAM_DETAILS_LEVEL">
          <FormSelect
            control={control}
            name="level"
            label="Level"
            disabled={props.disabled || props.isEditMode}
            isLoading={props.isLoadingOptions}
            options={props.levelOptions}
          />
        </Grid>
      </Grid>
      <ProgramDateAndTimeManager
        form={props.form}
        disabled={props.disabled}
        generatedComponents={props.generatedComponents}
        isEditing={props.isEditMode}
      />
    </Grid>
  );
};
