import { HeaderUnderLine } from "@components/HeaderUnderLine";
import { Chip, Grid, IconButton, styled, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { capitalize } from "@utils/capitalize";
import { Loader } from "@components/crud/Loader";
import { FormSelect } from "@components/FormSelect";
import { useQuery } from "@tanstack/react-query";
import {
  adminDirectoryGet,
  adminFeatureEpicGet,
  adminTestcaseReportGet,
  ModelJiraIssue,
  ModelReportTestCase
} from "@sportsgravyengineering/sg-api-react-sdk";
import { CustomColumnGraph } from "@pages/dashboard/components/CustomColumnGraph";
import { Column, DataGridTable } from "@components/DataGridTable";
import NoDataIcon from "@assets/icons/no-data-icon.svg";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { AddBox, IndeterminateCheckBox } from "@mui/icons-material";

const isFloat = (n) => {
  if (Number(n) === n && n % 1 !== 0) {
    return parseFloat(n.toFixed(2));
  }
  if (typeof n === "number") return n.toLocaleString();
  return n;
};

const formatDate = (isoString) => {
  const date = new Date(isoString);

  const options = {
    weekday: "short",
    day: "2-digit",
    month: "short",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true
  };

  return new Intl.DateTimeFormat("en-US", options).format(date);
};

const columns = [
  { label: "Backlog", color: "#E5E5E5" },
  { label: "Done", color: "#1ABC9C" },
  { label: "In Progress", color: "#FFC05E" }
];

const StyledCustomColumnGraph = styled("div")`
  .column-graph {
    margin: 8px 0 !important;
  }
  .graph {
    margin: 0px !important;
  }
`;

const StyledDiv = styled("div")`
  width: calc(100vw - 320px);
  margin-top: 32px;
  margin-bottom: 32px;
  .pinned-body {
    background-color: #fff !important;
  }
`;

const StyledLink = styled("a")`
  color: #007aff;
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
  padding: 10px 0;
  &:visited {
    color: #007aff;
  }
  &:hover {
    color: #004494;
  }
  &:active {
    color: #007aff;
  }
`;

type Insights = Array<{
  label: string;
  value: number;
}>;

export const TestcaseBreakdown = ({
  type,
  releaseId,
  platform,
  isDefualtExpanded
}: {
  type: "FAILED" | "BLOCKED" | "HOLD";
  releaseId: string;
  platform: "AND" | "IOS" | "WEB" | "MOB";
  isDefualtExpanded: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(isDefualtExpanded);
  const [platformFilter, setPlatformFilter] = useState<
    "AND" | "IOS" | "WEB" | "MOB"
  >(platform);
  const [resourceFilter, setResourceFilter] = useState<string | undefined>(
    undefined
  );
  const [epicFilter, setEpicFilter] = useState<string | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<
    "BACKLOG" | "INPROGRESS" | "DONE" | "ALL"
  >("ALL");
  const [secondaryTableRows, setSecondaryTableRows] = useState<
    {
      id: number;
      expanded: boolean;
      rows: ModelReportTestCase[];
      key: string;
    }[]
  >([]);

  useEffect(() => {
    setIsExpanded(isDefualtExpanded);
    setPlatformFilter(platform);
  }, [isDefualtExpanded, platform]);
  const { data: companyDirectory, isLoading: isCompanyDirectoryLoading } =
    useQuery(["companyDirectory"], () => adminDirectoryGet(), {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    });

  const { data: epics, isLoading: epicsLoading } = useQuery(
    ["epics"],
    () => adminFeatureEpicGet(),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    }
  );

  const query = useMemo(() => {
    const buildQuery = {} as {
      releaseId: string;
      platform: "AND" | "IOS" | "WEB" | "MOB";
      epicId: string;
      resourceId: string;
      status: "FAILED" | "BLOCKED" | "HOLD";
      cardStatus: "BACKLOG" | "INPROGRESS" | "DONE";
    };

    buildQuery.releaseId = releaseId;
    buildQuery.status = type;
    if (platformFilter) {
      buildQuery.platform = platformFilter;
    }
    if (resourceFilter && resourceFilter !== "ALL") {
      buildQuery.resourceId = resourceFilter;
    }
    if (epicFilter && epicFilter !== "ALL") {
      buildQuery.epicId = epicFilter;
    }
    if (statusFilter && statusFilter !== "ALL") {
      buildQuery.cardStatus = statusFilter;
    }

    return buildQuery;
  }, [releaseId, platformFilter, resourceFilter, epicFilter, statusFilter]);

  const epicOptions = useMemo(() => {
    if (epics && epics.data) {
      const options = epics.data
        .sort((a, b) => a?.summary.localeCompare(b?.summary))
        .map((epic) => ({
          label: epic.summary,
          value: epic.key
        }));
      return [{ label: "All Epics", value: "ALL" }, ...options];
    }
    return [{ label: "All Epics", value: "ALL" }];
  }, [epics]);

  const { data: report, isLoading: isLoading } = useQuery(
    [
      "testcaseReport",
      releaseId,
      type,
      platformFilter,
      resourceFilter,
      epicFilter,
      statusFilter
    ],
    () => adminTestcaseReportGet(query),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: true
    }
  );

  const resourcesOptions = useMemo(() => {
    let options: Array<{ label: string; value: string }> = [];
    if (companyDirectory?.data.persons) {
      options = companyDirectory.data.persons
        .filter((resource) => resource.sportsgravyUser)
        .map((resource) => ({
          label: `${resource.firstName} ${resource.lastName}`,
          value: resource?.sportsgravyUser?.jiraAccountId
        }));
    }
    options.unshift({ label: "All Resource", value: "ALL" });
    return options;
  }, [companyDirectory?.data]);

  const renderChip = (value) => {
    return (
      <>
        <Chip
          style={{
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: 600,
            height: "23px"
          }}
          label={value}
          sx={{
            background:
              value === "FAILED"
                ? "#FECACA"
                : value === "PASSED"
                ? "#BBF7D0"
                : value === "BLOCKED"
                ? "#FF9F0A"
                : value == "HOLD"
                ? "#FBF1BC"
                : "#E5E5E5",
            color:
              value === "FAILED"
                ? "#991B1B"
                : value === "PASSED"
                ? "#166534"
                : value === "BLOCKED"
                ? "#FFFFFF"
                : value == "HOLD"
                ? "#7B5B08"
                : "#666666"
          }}
        />
      </>
    );
  };

  const renderJiraStatus = (
    value:
      | "IN_PROGRESS"
      | "DONE"
      | "REVIEW"
      | "READY_COMMITTED"
      | "QA"
      | "TODAYS_PLAN"
  ) => {
    let status: "BACKLOG" | "IN PROGRESS" | "DONE" | undefined = undefined;
    if (value === "READY_COMMITTED") {
      status = "BACKLOG";
    } else if (value === "DONE") {
      status = "DONE";
    } else {
      status = "IN PROGRESS";
    }
    return (
      <>
        <Chip
          style={{
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: 600,
            height: "23px"
          }}
          label={status}
          sx={{
            background:
              status === "IN PROGRESS"
                ? "#FFC05E"
                : status === "DONE"
                ? "#1ABC9C"
                : "#E5E5E5",
            color:
              status === "IN PROGRESS"
                ? "#000"
                : status === "DONE"
                ? "#fff"
                : "#000"
          }}
        />
      </>
    );
  };

  const COLUMNS: Column[] = [
    {
      field: "jiraLink",
      headerName: "Jira Link",
      width: 120,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return (
          <StyledLink
            style={{ fontWeight: 600 }}
            href={`https://sportsgravy.atlassian.net/browse/${params?.card?.key}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params?.card?.key}
          </StyledLink>
        );
      }
    },
    {
      field: "jiraStatus",
      headerName: "Jira Status",
      width: 120,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        if (params?.card?.status) return renderJiraStatus(params?.card?.status);
        return <span></span>;
      }
    },
    {
      field: "resource",
      headerName: "Resource",
      width: 150,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return (
          <Typography>
            {params?.card?.assignee?.person?.firstName}{" "}
            {params?.card?.assignee?.person?.lastName}
          </Typography>
        );
      }
    },
    {
      field: "epic",
      headerName: "Epic",
      width: 100,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {params?.card?.parent && (
              <Chip
                label={params?.card?.parent?.summary}
                style={{
                  background: "#F7D4FF",
                  color: "#811998",
                  fontSize: "10px",
                  fontWeight: 600,
                  height: "23px",
                  maxWidth: "fit-content"
                }}
              />
            )}
          </div>
        );
      }
    },
    {
      field: "feature",
      headerName: "Feature",
      width: 300,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        const secondaryRow = secondaryTableRows.find(
          (row) => row.key === params?.card?.key
        );

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between"
            }}
          >
            <StyledLink
              style={{ fontWeight: 600 }}
              href={`/qa-features/${params?.testcases?.[0]?.featureId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {params?.testcases?.[0]?.name}
            </StyledLink>
            {secondaryRow?.rows && secondaryRow?.rows.length > 0 && (
              <>
                {secondaryRow?.expanded ? (
                  <KeyboardArrowUpIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const newRows = secondaryTableRows.map((row) => {
                        if (row.key === params?.card?.key) {
                          return { ...row, expanded: false };
                        }
                        return row;
                      });
                      setSecondaryTableRows(newRows);
                    }}
                  />
                ) : (
                  <KeyboardArrowDownIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      const newRows = secondaryTableRows.map((row) => {
                        if (row.key === params?.card?.key) {
                          return { ...row, expanded: true };
                        }
                        return row;
                      });
                      setSecondaryTableRows(newRows);
                    }}
                  />
                )}
              </>
            )}
          </div>
        );
      }
    }
  ];

  const SECONDARY_COLUMNS: Column[] = [
    {
      field: "description",
      headerName: "Description",
      width: 350,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return <Typography>{params.description}</Typography>;
      }
    },
    {
      field: "platform",
      headerName: "Platform",
      width: 100,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return <Typography>{params.platform}</Typography>;
      }
    },
    {
      field: "uiStatus",
      headerName: "UI Status",
      width: 100,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return renderChip(params.uiStatus);
      }
    },
    {
      field: "fnStatus",
      headerName: "FN Status",
      width: 100,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return renderChip(params.fnStatus);
      }
    },
    {
      field: "executedOn",
      headerName: "Executed On",
      width: 180,
      sortable: true,
      align: "left",
      renderCell: (params) => {
        return <Typography>{formatDate(params.executedOn)}</Typography>;
      }
    }
  ];

  const FAILED_COLUMNS: Column[] = [
    {
      field: "feature",
      headerName: "Feature",
      width: 350,
      sortable: false,
      align: "left",
      renderCell: (params) => {
        return (
          <StyledLink
            style={{ fontWeight: 600 }}
            href={`/qa-features/${params?.featureId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params?.name}
          </StyledLink>
        );
      }
    },
    {
      field: "count",
      headerName: "Total TC On Hold",
      width: 10,
      sortable: false,
      align: "center"
    }
  ];

  useEffect(() => {
    if (report && report.data && report.data?.data && type !== "HOLD") {
      const data = report.data?.data.map((d, index) => {
        return {
          id: index,
          expanded: false,
          key: (d.card as ModelJiraIssue).key as string,
          rows: d.testcases || []
        };
      });
      setSecondaryTableRows(data);
    }
  }, [report]);

  return (
    <Grid container direction="column" marginTop="32px">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center"
        }}
      >
        <div
          style={{ display: "flex", paddingLeft: "40px", alignItems: "center" }}
        >
          <IconButton
            onClick={() => {
              setIsExpanded(!isExpanded);
            }}
          >
            {isExpanded ? (
              <IndeterminateCheckBox color="primary" />
            ) : (
              <AddBox color="primary" />
            )}
          </IconButton>
          <Typography style={{ fontWeight: 700, fontSize: "16px" }}>
            TC {capitalize(type)} Breakdown
          </Typography>
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
            marginRight: "40px"
          }}
        >
          <div style={{ display: "flex", gap: "16px" }}>
            <FormSelect
              options={[
                { label: "MOB", value: "MOB" },
                { label: "iOS", value: "IOS" },
                { label: "AND", value: "AND" },
                { label: "API", value: "API" },
                { label: "WEB", value: "WEB" }
              ]}
              name=""
              sx={{ width: "100px" }}
              onChange={(e) => {
                setPlatformFilter(e.target.value);
              }}
            />
            {type != "HOLD" && (
              <>
                <FormSelect
                  options={[
                    { label: "All", value: "ALL" },
                    { label: "Backlog", value: "BACKLOG" },
                    { label: "Inprogress", value: "INPROGRESS" },
                    { label: "Done", value: "DONE" }
                  ]}
                  name=""
                  sx={{ width: "120px" }}
                  onChange={(e) => {
                    setStatusFilter(e.target.value);
                  }}
                />
                <FormSelect
                  options={epicOptions}
                  isLoading={epicsLoading}
                  name=""
                  sx={{ width: "150px" }}
                  onChange={(e) => {
                    setEpicFilter(e.target.value);
                  }}
                />
                <FormSelect
                  options={resourcesOptions}
                  isLoading={isCompanyDirectoryLoading}
                  name=""
                  sx={{ width: "150px" }}
                  onChange={(e) => {
                    setResourceFilter(e.target.value);
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <HeaderUnderLine marginLeft="20px" />
      {isExpanded && (
        <>
          {(report?.data || isLoading) && (
            <Grid
              item
              style={{
                minHeight: isLoading ? "300px" : "auto",
                marginLeft: "25px",
                padding: "0px 15px",
                marginRight: "25px",
                display: isLoading ? "flex" : "block"
              }}
            >
              <Loader isLoading={isLoading}>
                <Grid
                  container
                  direction="column"
                  padding="0px"
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.23)",
                    boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.08)",
                    borderRadius: "10px",
                    marginTop: "32px"
                  }}
                >
                  {report?.data?.insights &&
                    Array.from(
                      {
                        length: 1
                      },
                      (_, index) => index
                    ).map((row, index) => {
                      const insights: Insights = report?.data
                        ?.insights as Insights;
                      return (
                        <Grid key={index} container direction="row" item>
                          {insights &&
                            insights.map((con) => {
                              return (
                                <Grid
                                  item
                                  container
                                  direction="column"
                                  key={con.label}
                                  xs={type === "HOLD" ? 4 : 3}
                                  minHeight="121px"
                                  padding="24px"
                                  alignItems="center"
                                  justifyContent="center"
                                  style={{
                                    border: "1px solid rgba(229, 229, 229, 1)"
                                  }}
                                >
                                  <Grid item>
                                    <Typography
                                      style={{
                                        color: "#64748B",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        lineHeight: "21px"
                                      }}
                                    >
                                      {con.label}
                                    </Typography>
                                  </Grid>
                                  <Grid item>
                                    <Typography
                                      style={{
                                        color: "#1E293B",
                                        fontWeight: 600,
                                        fontSize: "32px",
                                        lineHeight: "48px"
                                      }}
                                    >
                                      {isFloat(con.value)}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              );
                            })}
                        </Grid>
                      );
                    })}
                </Grid>
                {type !== "HOLD" && (
                  <>
                    <div
                      style={{
                        marginTop: "24px",
                        display: "flex",
                        gap: "16px",
                        alignItems: "center"
                      }}
                    >
                      <span>
                        Total Jira Cards (
                        {(report?.data?.insights as Insights)?.[3].value})
                      </span>
                      {columns.map((value, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              gap: "8px",
                              alignItems: "center"
                            }}
                          >
                            <span
                              style={{
                                width: "16px",
                                height: "16px",
                                background: value.color
                              }}
                            ></span>
                            <span>{value.label}</span>
                          </div>
                        );
                      })}
                    </div>
                    <StyledCustomColumnGraph>
                      <CustomColumnGraph
                        columns={columns}
                        values={report?.data.testcaseBreakdown}
                        showLabel={false}
                        showToolbar={false}
                      />
                    </StyledCustomColumnGraph>
                    <StyledDiv>
                      {report?.data && (
                        <DataGridTable
                          rows={
                            report.data.data &&
                            report?.data.data.sort(
                              (a, b) => b?.card?.key - a?.card?.key
                            )
                          }
                          // rows={report.data.data!}
                          columns={COLUMNS}
                          secondaryTableColumns={SECONDARY_COLUMNS}
                          secondaryTableData={secondaryTableRows}
                        />
                      )}
                    </StyledDiv>
                  </>
                )}
                {type === "HOLD" && (
                  <StyledDiv>
                    {report?.data.data && (
                      <DataGridTable
                        rows={(
                          report?.data.data as ModelReportTestCase[]
                        ).reduce((acc: ModelReportTestCase[], current) => {
                          const existingItem = acc.find(
                            (item) => item.featureId === current.featureId
                          );
                          let additionalCount = 0;

                          if (current.uiStatus === "HOLD") {
                            additionalCount += 1;
                          }
                          if (current.fnStatus === "HOLD") {
                            additionalCount += 1;
                          }

                          if (existingItem) {
                            existingItem.count =
                              (existingItem.count || 1) + 1 + additionalCount;
                          } else {
                            acc.push({
                              ...current,
                              count: 1 + additionalCount
                            });
                          }
                          return acc;
                        }, [])}
                        columns={FAILED_COLUMNS}
                      />
                    )}
                  </StyledDiv>
                )}
              </Loader>
            </Grid>
          )}
          {!report?.data && !isLoading && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  margin: "auto"
                }}
              >
                <img src={NoDataIcon} style={{ width: "64px" }} />
                <Typography
                  style={{
                    color: "#64748b",
                    fontSize: "14px",
                    fontWeight: 500,
                    padding: "16px 24px"
                  }}
                >
                  No data available
                </Typography>
              </div>
            </>
          )}
        </>
      )}
    </Grid>
  );
};
