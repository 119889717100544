import { Avatar, Box, Container, Grid, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { MobileFooter } from "./components/MobileFooter";
import { useEffect, useState } from "react";
import { DesktopHeader } from "./components/DesktopHeader";
import { DesktopFooter } from "./components/DesktopFooter";
import {
  ModelStream,
  useLiveStreamUnauthorizedGet
} from "@sportsgravyengineering/sg-api-react-sdk";
import { DownloadAppPopup } from "./components/DownloadAppPopup";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import SettingIcon from "@assets/icons/settingIcon.svg";
import Flagicon from "@assets/icons/flagIcon.svg";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import ShareIcon from "@assets/icons/livestreamShareIcon.svg";
import EventUpdateIcon from "@assets/icons/updateHistoryIcon.svg";
import LikeIcon from "@assets/icons/livestreamLikeIcon.svg";
import { AD_LAYOUT_OPTIONS } from "@utils/constants";
import RedirectIcon from "@assets/icons/redirectIcon.svg";
import LivestreamSwitchIcon from "@assets/icons/livestreamSwitchIcon.svg";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import LiveStreamBanner from "@assets/images/livestreamBanner.png";
import { useNavigate, useParams } from "react-router-dom";
import SGLogoLoader from "@assets/images/SGLogoLoader.gif";

const StyledBox = styled(Box)`
  height: fit-content;
  min-height: 100vh;
  background-color: #f0f2f7;
  .desktop-footer {
    padding: 16px;
  }
  @media (max-width: 600px) {
    .desktop-footer p {
      font-size: 12px !important;
    }
    .btn-container img {
      width: 90px;
    }
  }
`;

const StyledLiveStream = styled(Box)`
  background-color: #fff;
  padding: 24px;
  border-radius: 6px;
  border: 1px solid #0000001f;
  box-shadow: 0px 4px 14px 0px #00000021;
  margin: 24px 0px;

  .thumnail-container {
    position: relative;
    background-color: #000;
  }
  .thumbnail {
    display: block;
    margin: auto;
    height: 470px;
    max-width: 100%;
    opacity: 0.6;
  }
  .thumbnail-content {
    position: absolute;
    text-align: center;
    color: #fff;
    .header {
      font-size: 33px;
      line-height: 45px;
    }
    .reason {
      font-weight: 700;
      font-size: 83px;
      letter: -4%;
      line-height: 96px;
    }
    .description {
      font-size: 32px;
      line-height: 43px;
    }
    @media (max-width: 767px) {
      .header {
        font-size: 26px;
        line-height: 33px;
      }
      .reason {
        font-size: 60px;
        line-height: 90px;
      }
      .description {
        font-size: 26px;
        line-height: 33px;
      }
    }
    @media (max-width: 565px) {
      .header {
        font-size: 20px;
        line-height: 23px;
      }
      .reason {
        font-size: 50px;
        line-height: 60px;
      }
      .description {
        font-size: 20px;
        line-height: 33px;
      }
    }
    @media (max-width: 365px) {
      .header {
        font-size: 16px;
        line-height: 20px;
      }
      .reason {
        font-size: 30px;
        line-height: 50px;
      }
      .description {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
  .top-bar {
    display: flex;
    position: absolute;
    gap: 30px;
    top: 16px;
    left: 16px;
    justify-content: flex-end;
    width: calc(100% - 32px);
  }
  .bottom-bar .live-indicator {
    width: 10px;
    height: 10px;
    background-color: #e82c2c;
    border-radius: 99px;
  }
  .bottom-bar {
    display: flex;
    position: absolute;
    bottom: 16px;
    left: 16px;
    justify-content: space-between;
    width: calc(100% - 32px);
  }
  .bottom-bar div {
    display: flex;
    gap: 4px;
    align-items: center;
    color: #fff;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 700;
  }
  .progress-bar {
    height: 2px;
    background-color: #e82c2c;
  }
  .completed-progress-bar {
    position: absolute;
    bottom: -5px;
    left: 5px;
    transform: translateX(-50%);
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #e82c2c;
  }
  @media (max-width: 960px) {
    padding: 0px;
    margin: 0px;
    margin-bottom: 24px;
  }

  @media (max-width: 565px) {
    .thumbnail {
      height: 320px;
    }
  }

  @media (max-width: 357px) {
    .thumbnail {
      max-height: 250px;
    }
    .unavailable {
      font-size: 12px;
      line-height: 16px;
      bottom: 8px;
      left: 8px;
      width: calc(100% - 16px);
    }
  }
  @media (max-width: 280px) {
    .thumbnail {
      max-height: 200px;
    }
  }
`;

const LiveStreamDetails = styled(Box)`
  @media (max-width: 960px) {
    padding: 0px 24px;
  }
  @media (max-width: 465px) {
    padding: 0px 15px;
  }
`;

const LiveStreamTitle = styled(Typography)`
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-top: 16px;
  @media (max-width: 465px) {
    font-size: 14px;
    line-height: 18px;
  }
`;

const LiveStreamWatching = styled(Typography)`
  font-size: 12px;
  line-height: 17px;
  font-weight: 600;
  color: #666666;
  @media (max-width: 465px) {
    font-size: 11px;
    line-height: 15px;
  }
`;

const OrgDetails = styled(Box)`
  display: flex;
  gap: 12px;
  margin: 16px 0px;
  .MuiAvatar-root {
    width: 46px;
    height: 46px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .org-name {
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    color: #000;
  }
  @media (max-width: 960px) {
    padding: 0px 24px;
  }
  @media (max-width: 465px) {
    padding: 0px 15px;
    .MuiAvatar-root {
      width: 24px;
      height: 24px;
    }
    .org-name {
      font-size: 12px;
    }
  }
`;

const ActionBar = styled(Box)`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  div {
    width: 140px;
    height: 40px;
    border-radius: 99px;
    background-color: #f3f4f7;
    gap: 8px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    img {
      width: 24px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      color: #242424;
    }
  }
  @media (max-width: 960px) {
    padding: 0px 24px;
  }
  @media (max-width: 465px) {
    padding: 0px 15px;
    overflow: auto;
    div {
      padding: 6px 12px;
      min-width: fit-content;
      p {
        font-size: 11px;
      }
    }
  }
`;

const CommentBox = styled(Box)`
  margin: 16px 0;
  padding: 8px;
  background-color: #f3f4f7;
  border-radius: 6px;
  .comment-box {
    display: flex;
    gap: 8px;
    align-items: center;
    .comment-box-header {
      font-size: 12px;
      font-weight: 600;
      line-height: 18px;
      color: #242424;
    }
    .comment-box-count {
      font-size: 12px;
      font-weight: 500;
      line-height: 15px;
      color: #666666;
    }
  }
  @media (max-width: 960px) {
    margin: 16px 24px;
  }
  @media (max-width: 465px) {
    margin: 16px 15px;
  }
`;

const Comment = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
  padding-top: 16px;
  .MuiAvatar-root {
    width: 32px;
    height: 32px;
  }
  .comment {
    font-size: 16px;
    line-height: 20px;
    color: #000000e5;
  }
  @media (max-width: 465px) {
    .MuiAvatar-root {
      width: 24px;
      height: 24px;
    }
    .comment {
      font-size: 11px;
      line-height: 15px;
    }
  }
`;

const AdBox = styled(Box)`
  margin-top: 16px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
  }
`;

const StyledPlayIcon = styled(PlayArrowOutlinedIcon)`
  background-color: #000000;
  opacity: 0.75;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
`;

const SponsoredByText = styled(Typography)`
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #b3b3b3;
  padding: 2px 8px 8px 0px;
  @media (max-width: 960px) {
    padding: 2px 8px 8px 8px;
  }
`;

const SponsoreBox = styled(Box)`
  background-color: #dadada;
  margin: 2px 8px 8px 8px;
`;

const AdTitle = styled(Typography)`
  max-width: 80%;
  padding: 0 15px 0 0;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 4px;
  text-transform: capitalize;
  @media (max-width: 960px) {
    padding: 0 15px 0 8px;
  }
`;

const AdDescription = styled(Typography)`
  text-align: "left";
  font-size: "12px";
  max-width: "90%";
  padding: "0 15px 0 0";
  font-weight: 400;
  line-height: "18px";
  margin-bottom: 4px;
  @media (max-width: 960px) {
    padding: 0 15px 0 8px;
  }
`;

const AdLogo = styled(Box)`
  padding-right: 8px;
  @media (max-width: 960px) {
    padding: 0 0 0 8px;
  }
`;

const NotifyContainer = styled(Box)`
  position: absolute;
  background-color: #000000bf;
  bottom: 16px;
  left: 16px;
  color: #fff;
  padding: 16px;
  border-radius: 6px;
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: flex-start;
  img {
    width: 24px;
  }
  p:first-of-type {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  p:nth-of-type(2) {
    margin-top: 2px;
    color: #ffffff80;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
  .notify-container {
    display: flex;
    align-items: center;
    padding: 6px 12px;
    gap: 4px;
    border-radius: 99px;
    background-color: #666666;
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  @media (max-width: 465px) {
    justify-content: space-between;
    width: calc(100% - 24px);
    left: 12px;
    bottom: 12px;
    padding: 8px 8px;
    svg {
      width: 16px;
    }
    p:first-of-type {
      font-size: 12px;
      line-height: 15px;
    }
    p:nth-of-type(2) {
      font-size: 10px;
      line-height: 15px;
    }
  }
  .notify-container {
    padding: 4px 12px;
    p {
      font-size: 12px;
      line-height: 15px;
    }
  }
  @media (max-width: 320px) {
    gap: 0px;
    p:first-of-type {
      font-size: 11px;
    }
    p:nth-of-type(2) {
      font-size: 9px;
    }
  }
`;

const LiveStreamCancelled = styled(Box)`
  position: absolute;
  background-color: #e82c2c;
  bottom: 16px;
  left: 16px;
  color: #fff;
  width: calc(100% - 32px);
  padding: 16px 24px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  @media (max-width: 465px) {
    font-size: 14px;
    padding: 8px 24px;
  }
`;

const StyledSGLogoLoader = styled(Box)`
  display: flex;
  justify-content: center;
  height: 100vh;
  align-items: center;
  img {
    width: 150px;
  }
  @media (max-width: 465px) {
    img {
      width: 100px;
    }
  }
`;

const dateFormat = (date) => {
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: true
  };
  //@ts-ignore
  return new Intl.DateTimeFormat("en-US", options).format(new Date(date));
};

const calculateTimeDifference = (startedAt, endedAt) => {
  const start = new Date(startedAt);
  const end = new Date(endedAt);

  const diffInMilliseconds = end.getTime() - start.getTime();

  const hours = Math.floor(diffInMilliseconds / 1000 / 60 / 60);
  const minutes = Math.floor((diffInMilliseconds / 1000 / 60) % 60);
  const seconds = Math.floor((diffInMilliseconds / 1000) % 60);

  return hours > 0
    ? `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`
    : `${minutes.toString().padStart(2, "0")}:${seconds
        .toString()
        .padStart(2, "0")}`;
};

export const LiveStream = () => {
  const navigate = useNavigate();
  const [showMobileFooter, setShowMobileFooter] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const { compressedUrl } = useParams();

  const {
    data: livestream,
    isLoading: isLoading,
    error: error
  } = useLiveStreamUnauthorizedGet(compressedUrl as string);

  useEffect(() => {
    console.log("error", error);

    if (error) navigate("/not-found");
  }, [error]);

  const [adDetails, setAdDetails] = useState({
    title: "",
    description: "",
    media: "",
    logo: "",
    redirect: "",
    businessName: ""
  });

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = window.navigator.userAgent;
      setIsMobile(/Mobile/i.test(userAgent));
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  useEffect(() => {
    if (
      livestream?.data?.liveStream?.advertisements &&
      livestream.data?.liveStream?.advertisements.length > 0
    ) {
      const ad = livestream?.data?.liveStream.advertisements[0];
      setAdDetails({
        title: ad.title || "",
        description: ad.description || "",
        media:
          ad.media?.baseUrl && ad.media?.path
            ? ad.media.baseUrl + ad.media.path
            : "",
        logo:
          ad.advertiser?.logo?.baseUrl && ad.advertiser?.logo?.path
            ? ad.advertiser.logo.baseUrl + ad.advertiser.logo.path
            : "",
        redirect: ad.redirectLink || "",
        businessName: ad.advertiser?.businessName
          ? ad.advertiser?.businessName
          : ""
      });
    }
  }, [livestream]);

  const AdMedia = (adProperties) => {
    if (adDetails.media) {
      return (
        <>
          <img
            data-testid="ad-media"
            src={adDetails.media}
            style={{
              objectFit: "contain",
              height: adProperties?.size.height + "px",
              width: "100%"
            }}
          />
          <img
            src={RedirectIcon}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              width: "30px",
              height: "30px",
              zIndex: 1
            }}
          />
        </>
      );
    }
  };

  const outStreamAdRender = (layout) => {
    const adProperties = AD_LAYOUT_OPTIONS.find(
      (option) => option.value == layout
    );
    return (
      <Grid container>
        {adProperties?.fields.includes("logo") && (
          <AdLogo>
            <Avatar
              data-testid="ad-logo"
              sx={{
                bgcolor: "#000",
                color: "#fff !important",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "left"
              }}
              src={adDetails.logo}
              variant="square"
            ></Avatar>
          </AdLogo>
        )}
        <Grid xs container direction="column">
          {adProperties?.Imageposition.includes("top") && (
            <SponsoreBox
              style={{
                width: "100%",
                height: adProperties?.size.height + "px",
                position: "relative",
                margin: "0 0 4px 0"
              }}
            >
              {AdMedia(adProperties)}
            </SponsoreBox>
          )}
          {adProperties?.fields.includes("title") && (
            <AdTitle
              style={{
                ...(adProperties.label.includes("Contextual") && {
                  maxWidth: "100%",
                  height: "18px",
                  color: "#3B6CF8",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textWrap: "nowrap"
                })
              }}
            >
              {adDetails.title ? adDetails.title : "Ad Title"}
            </AdTitle>
          )}
          {adProperties?.Imageposition.includes("middle") && (
            <SponsoreBox
              data-testid="ad-media-middle"
              style={{
                width: "100%",
                height: adProperties?.size.height + "px",
                position: "relative",
                margin: "0 0 4px 0"
              }}
            >
              {AdMedia(adProperties)}
            </SponsoreBox>
          )}
          {!adProperties?.fields.includes("image") && (
            <img
              src={RedirectIcon}
              style={{
                position: "absolute",
                top: "1px",
                right: "10px",
                width: "25px",
                height: "25px",
                zIndex: 1,
                margin: "0 10px 0 0"
              }}
            />
          )}
          {adProperties?.fields.includes("description") && (
            <AdDescription
              style={{
                ...(adProperties.label.includes("Contextual") && {
                  maxWidth: "100%",
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: 2
                })
              }}
            >
              {adDetails.description ? adDetails.description : "Ad Description"}
            </AdDescription>
          )}
          {adProperties?.Imageposition.includes("bottom") && (
            <SponsoreBox
              style={{
                width: "100%",
                height: adProperties?.size.height + "px",
                position: "relative",
                margin: "0 0 0px 0",
                bottom: "0x"
              }}
            >
              {AdMedia(adProperties)}
            </SponsoreBox>
          )}
        </Grid>
      </Grid>
    );
  };

  const SingleLiveStream = ({ content }: { content: ModelStream }) => {
    return (
      <StyledLiveStream>
        <div className="thumnail-container">
          {["LIVE", "COMPLETED", "INTERMISSION"].includes(
            content?.status as string
          ) && (
            <div className="top-bar">
              <img src={LivestreamSwitchIcon} />
              <img src={SettingIcon} />
              <img src={Flagicon} />
            </div>
          )}
          {["UNAVAILABLE", "PAUSED"].includes(content?.status as string) ? (
            <div
              className="thumbnail-container"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <img
                className="thumbnail"
                style={{ width: "100%", opacity: 1 }}
                src={LiveStreamBanner}
              />
              <div className="thumbnail-content">
                <div className="header">Live Stream is</div>
                <div className="reason">
                  {content?.status == "UNAVAILABLE" ? "Unavailable" : "Paused"}
                </div>
                {content?.status == "UNAVAILABLE" && (
                  <div className="description">Sorry for the inconvenience</div>
                )}
              </div>
            </div>
          ) : (
            <img
              className="thumbnail"
              src={
                content?.thumbnail?.baseUrl && content?.thumbnail.path
                  ? content?.thumbnail?.baseUrl + content?.thumbnail?.path
                  : ""
              }
            />
          )}
          {["LIVE", "COMPLETED", "INTERMISSION"].includes(
            content?.status as string
          ) && (
            <>
              <StyledPlayIcon fontSize="large" />
              <div className="bottom-bar">
                {["LIVE", "INTERMISSION", "PAUSED"].includes(
                  content?.status as string
                ) && (
                  <Box>
                    <span
                      className="live-indicator"
                      style={{
                        backgroundColor:
                          content?.status == "PAUSED" ? "#B3B3B3" : "#E82C2C"
                      }}
                    ></span>
                    <span>LIVE</span>
                  </Box>
                )}
                {["COMPLETED"].includes(content?.status as string) && (
                  <Box>
                    <span>
                      0:00 /{" "}
                      {calculateTimeDifference(
                        content?.startedAt,
                        content?.endedAt
                      )}
                    </span>
                  </Box>
                )}

                <FullscreenIcon style={{ color: "#fff" }} />
              </div>
              {["LIVE", "INTERMISSION"].includes(content?.status as string) && (
                <div className="progress-bar"></div>
              )}
              {["COMPLETED"].includes(content?.status as string) && (
                <div className="completed-progress-bar"></div>
              )}
            </>
          )}
          {["NOT_STARTED", "POSTPONED", "DELAYED"].includes(
            content?.status as string
          ) && (
            <NotifyContainer>
              <Box>
                <Typography>Goes Live On</Typography>
                <Typography>
                  {dateFormat(
                    content.status != "NOT_STARTED"
                      ? content.delayedUntil
                      : content.scheduledAt
                  )}
                </Typography>
              </Box>
              <div className="notify-container">
                <NotificationsNoneIcon />
                <Typography>Notify Me</Typography>
              </div>
            </NotifyContainer>
          )}
          {content.status == "CANCELLED" && (
            <LiveStreamCancelled>Live Stream Canceled</LiveStreamCancelled>
          )}
          {content.status == "UNAVAILABLE" && (
            <LiveStreamCancelled
              className="unavailable"
              style={{ backgroundColor: "#000000BF" }}
            >
              We will notify you when a fully recorded version of this live
              stream is available
            </LiveStreamCancelled>
          )}
        </div>
        <LiveStreamDetails>
          <LiveStreamTitle>{content.title}</LiveStreamTitle>
          {["LIVE", "COMPLETED", "PAUSED", "INTERMISSION"].includes(
            content.status as string
          ) && (
            <LiveStreamWatching sx={{ marginRight: "8px" }} display="inline">
              {content._count?.viewers
                ? content._count.viewers > 1000
                  ? (content._count.viewers / 1000).toFixed(1) +
                    `K ${
                      content.status == "LIVE" ||
                      content.status == "INTERMISSION"
                        ? "watching"
                        : "viewes"
                    }`
                  : content._count.viewers.toLocaleString() +
                    `${
                      content.status == "LIVE" ||
                      content.status == "INTERMISSION"
                        ? " watching"
                        : " viewes"
                    }`
                : `0 ${
                    content.status == "LIVE" || content.status == "INTERMISSION"
                      ? "watching"
                      : "viewes"
                  }`}
            </LiveStreamWatching>
          )}
          {content.tags && content.tags.length > 0 && (
            <LiveStreamWatching display="inline">
              {content.tags && "#" + content?.tags[0]}
            </LiveStreamWatching>
          )}
          {content.tags && content.tags.length > 1 && (
            <LiveStreamWatching
              sx={{ marginLeft: "8px", color: "#000" }}
              display="inline"
            >
              ...more
            </LiveStreamWatching>
          )}
        </LiveStreamDetails>
        <OrgDetails>
          {content?.organization?.avatarId ? (
            <Avatar
              variant="square"
              src={
                content?.organization?.avatar?.baseUrl &&
                content?.organization?.avatar?.path
                  ? content?.organization?.avatar?.baseUrl +
                    content?.organization?.avatar?.path
                  : ""
              }
            />
          ) : (
            <Avatar
              sx={{
                bgcolor: "#" + content.organization?.colorCode,
                color: "#fff !important",
                padding: "14px",
                fontSize: "14px",
                fontWeight: 700,
                lineHeight: "20px",
                textAlign: "center"
              }}
              variant="square"
            >
              {`${content.organization?.abbreviation?.substring(0, 2)}`
                .split("")
                .map((name) => name[0])
                .join("")
                .toUpperCase()}
            </Avatar>
          )}
          <Box>
            <Typography className="org-name">
              {content.organization?.name}
            </Typography>
          </Box>
        </OrgDetails>
        <ActionBar>
          <Box>
            <img src={LikeIcon} />
            <Typography>{content._count?.likes}</Typography>
          </Box>
          <Box>
            <img src={EventUpdateIcon} />
            <Typography>Event Updates</Typography>
          </Box>
          <Box>
            <img src={ShareIcon} />
            <Typography>Share</Typography>
          </Box>
        </ActionBar>
        {(content._count?.comments as number) > 0 && (
          <CommentBox>
            <div className="comment-box">
              <Typography className="comment-box-header">Comments</Typography>
              <Typography className="comment-box-count">
                {content._count?.comments
                  ? content._count.comments > 1000
                    ? (content._count.comments / 1000).toFixed(1) + "K"
                    : content._count.comments
                  : "0"}
              </Typography>
            </div>
            <Comment>
              {content.comments?.[0].user?.person?.avatarId ? (
                <Avatar
                  variant="square"
                  src={
                    content.comments?.[0].user?.person?.avatar?.baseUrl &&
                    content.comments?.[0].user?.person?.avatar?.path
                      ? content.comments?.[0].user?.person?.avatar?.baseUrl +
                        content.comments?.[0].user?.person?.avatar?.path
                      : ""
                  }
                />
              ) : (
                <Avatar
                  sx={{
                    bgcolor:
                      "#" + content.comments?.[0].user?.person?.colorCode,
                    color: "#fff !important",
                    padding: "14px",
                    fontSize: "14px",
                    fontWeight: 700,
                    lineHeight: "20px",
                    textAlign: "center"
                  }}
                  variant="square"
                >
                  {`${content.comments?.[0].user?.person?.firstName} ${content.comments?.[0].user?.person?.lastName}`
                    .split(" ")
                    .map((name) => name[0])
                    .join("")
                    .toUpperCase()}
                </Avatar>
              )}
              <Box>
                <Typography className="comment">
                  {content.comments?.[0].comment}
                </Typography>
              </Box>
            </Comment>
          </CommentBox>
        )}
        {content.advertisements && content.advertisements.length > 0 && (
          <AdBox>
            <div>
              <SponsoredByText>Sponsored By</SponsoredByText>
              <div style={{ minHeight: "fit-content", position: "relative" }}>
                {outStreamAdRender(content?.advertisements?.[0].layout)}
              </div>
            </div>
          </AdBox>
        )}
      </StyledLiveStream>
    );
  };

  useEffect(() => {
    const preventZoom = (e) => {
      if (e.touches.length > 1) {
        e.preventDefault();
      }
    };

    document.addEventListener("touchmove", preventZoom, { passive: false });

    return () => {
      document.removeEventListener("touchmove", preventZoom);
    };
  }, []);

  if (isLoading) {
    return (
      <StyledSGLogoLoader>
        <img src={SGLogoLoader} />
      </StyledSGLogoLoader>
    );
  }

  return (
    <>
      <StyledBox>
        <DesktopHeader
          mobileUrl={`sportsgravy://live-stream?id=${compressedUrl}`}
          isMobile={isMobile}
        />
        <Container style={{ ...(isMobile && { padding: 0 }) }}>
          <Box
            onClick={() => {
              setShowPopup(true);
            }}
            sx={{
              boxShadow: "0px 4px 14px 0px #00000021",
              backgroundColor: "#eceef3"
            }}
          >
            {livestream?.data?.liveStream && (
              <SingleLiveStream content={livestream?.data?.liveStream} />
            )}
          </Box>
          {isMobile && showMobileFooter && (
            <MobileFooter
              mobileUrl={`sportsgravy://live-stream?id=${compressedUrl}`}
              toggleMobileFooter={setShowMobileFooter}
            />
          )}
          {!isMobile && <DesktopFooter />}
          {showPopup && (
            <DownloadAppPopup
              mobileUrl={`sportsgravy://live-stream?id=${compressedUrl}`}
              title="Don’t miss out on the action"
              description="Watch this live stream and many more using the SportsGravy app."
            />
          )}
        </Container>
      </StyledBox>
    </>
  );
};
