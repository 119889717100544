import { Button } from "@components/Button";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { StepProgressLine } from "@components/StepProgressLine";
import { Container } from "@components/crud/Container";
import { Footer } from "@components/crud/Footer";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import { organizationAtom } from "@recoil/auth";
import Sentry from "@services/Sentry";
import {
  ModelPerson,
  ModelRole,
  ModelRolePermission,
  RoleAlias,
  TeamWithOrgCreateInput,
  useAdminLevelGet,
  useAdminPermissionGet,
  useAdminPositionGet,
  useAdminRoleGet,
  useAdminRoleRoleIdUserGet,
  useAdminSeasonGet,
  useAdminSportGet,
  useAdminTeamPost
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useSnackbar } from "notistack";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import {
  CoachOption,
  CoachSelectionForm
} from "../components/CoachSelectionForm";
import {
  ManagerOption,
  ManagerSelectionForm
} from "../components/ManagerSelectionForm";
import {
  PlayerOption,
  PlayerSelectionForm
} from "../components/PlayerSelectionForm";
import { TeamDetailsForm } from "./TeamDetailsForm";
import { PermissionSelectionForm } from "../components/PermissionSelectionForm";
import { cleanObject } from "../../../utils/cleanObject";

import { Grid } from "@mui/material";
import { DevTool } from "@hookform/devtools";

const steps = [
  "Team Details",
  "Player Selection",
  "Coach Selection",
  "Manager Selection",
  "Permissions"
];

export const TeamCreate = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const organizationId = useRecoilValue(organizationAtom);

  if (!organizationId) {
    navigate("/organizations");
  }

  const [tab, setTab] = useState("Team Details");

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const activeStepNumber = steps.findIndex((step) => step === tab);

  const form = useForm({
    mode: "onBlur"
  });
  const {
    control,
    // watch,
    trigger,
    handleSubmit,
    formState: { isValid }
  } = form;

  const [sportId, setSportId] = useState<string | undefined>(undefined);
  const [seasonId, setSeasonId] = useState<string | undefined>(undefined);
  const [players, setPlayers] = useState<string[]>([]);
  const [playersWithValues, setPlayersWithValues] = useState<PlayerOption[]>(
    []
  );
  const [coachesWithValues, setCoachesWithValues] = useState<CoachOption[]>([]);
  const [managerWithValues, setManagerWithValues] = useState<ManagerOption[]>(
    []
  );
  const [defaultPermissions, setDefaultPermissions] = useState<
    (ModelRole & {
      permissions: ModelRolePermission[];
    })[]
  >([]);

  const [coaches, setCoaches] = useState<string[]>([]);
  const [managers, setManagers] = useState<string[]>([]);

  const { data: permissionRequest } = useAdminPermissionGet();

  const permissionsList = useMemo(() => {
    if (permissionRequest?.data) {
      const children = permissionRequest.data.find(
        (permission) => permission.permissionId === "team"
      )?.children;
      return (
        children?.filter(
          (permission) => permission.permissionId?.includes("associated")
        ) || []
      );
    }
    return [];
  }, [permissionRequest]);

  const { data: playerUserOptions, isLoading: playerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.PLAYER, {
      seasonId: seasonId,
      sportId: sportId,
      type: "TEAM",
      organizationId: organizationId
    });
  const [playerOptionsList, setPlayerOptionsList] = useState<ModelPerson[]>([]);
  useEffect(() => {
    if (playerUserOptions) {
      setPlayerOptionsList(playerUserOptions.data);
    }
  }, [playerUserOptions]);
  const { data: coachUserOptions, isLoading: coachUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.COACH, {
      organizationId: organizationId
    });
  const [coachOptionsList, setCoachOptionsList] = useState<ModelPerson[]>([]);

  useEffect(() => {
    if (coachUserOptions) {
      setCoachOptionsList(coachUserOptions.data);
    }
  }, [coachUserOptions]);
  const { data: managerUserOptions, isLoading: managerUserOptionsLoading } =
    useAdminRoleRoleIdUserGet(RoleAlias.MANAGER, {
      organizationId: organizationId
    });

  const [managerOptionsList, setManagerOptionsList] = useState<ModelPerson[]>(
    []
  );

  useEffect(() => {
    if (managerUserOptions) {
      setManagerOptionsList(managerUserOptions.data);
    }
  }, [managerUserOptions]);

  const { data: roleOptions } = useAdminRoleGet({
    pageSize: "100",
    organizationId: organizationId,
    includeChildren: true
  });

  const coachesSubRole = useMemo(() => {
    return roleOptions?.data?.roles
      ?.find((item) => item.alias === "COACH")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const managerSubRole = useMemo(() => {
    return roleOptions?.data?.roles
      ?.find((item) => item.alias === "MANAGER")
      ?.children?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.roleId
        };
      });
  }, [roleOptions]);

  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: seasonOptions, isLoading: seasonOptionsLoading } =
    useApiSelectOptions({
      api: useAdminSeasonGet,
      dataField: "results",
      labelField: "name",
      valueField: "seasonId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const { options: levelOptions, isLoading: levelOptionsLoading } =
    useApiSelectOptions({
      api: useAdminLevelGet,
      dataField: "levels",
      labelField: "name",
      valueField: "levelId",
      params: {
        sportId: sportId,
        organizationId: organizationId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  const { options: positionOptions } = useApiSelectOptions({
    api: useAdminPositionGet,
    dataField: "positions",
    labelField: "name",
    valueField: "positionId",
    params: {
      sportId: sportId
    },
    options: {
      query: {
        enabled: !!sportId
      }
    }
  });

  const onBackClick = () => {
    setTab(steps[activeStepNumber - 1]);
    setTimeout(() => {
      trigger();
    }, 50);
  };

  const onCancelClick = () => {
    setIsConfirmationDialogOpen(true);
  };

  const onConfirmCancel = () => {
    setIsConfirmationDialogOpen(false);
    navigate("/teams");
  };

  const onCancelCancel = () => {
    setIsConfirmationDialogOpen(false);
  };

  const onContinueClick = () => {
    setTab(steps[activeStepNumber + 1]);
  };

  const getPrimaryAction = () => {
    if (activeStepNumber < steps.length - 1) {
      return onContinueClick;
    } else {
      return handleSubmit(onSaveClick);
    }
  };

  const { mutate: save, isLoading: isSaving } = useAdminTeamPost();
  const onSaveClick = async (formValues) => {
    try {
      const users = [
        ...playersWithValues,
        ...coachesWithValues,
        ...managerWithValues
      ];
      const cleanedUsers = users.map((user) => cleanObject(user));
      const cleanedUsersTrimmed = cleanedUsers.map(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ({ user, ...rest }) => rest
      );
      const cleanDefaultPermissions = defaultPermissions
        .map((permission) => {
          delete permission.children;
          const dp = cleanObject(permission);
          delete dp.createdAt;
          return dp;
        })
        .filter(
          (role) =>
            // if parent role is selected, allow
            // but if it's a child role, only allow if it has users
            !role.parentId ||
            (role.parentId &&
              cleanedUsers.some((user) => user.roleId === role.roleId))
        );
      const data = {
        name: formValues.name,
        sportId: sportId,
        levelId: formValues.level,
        gender: formValues.gender,
        seasonId: formValues.season,
        organizationId: organizationId,
        users: cleanedUsersTrimmed,
        defaultPermissions: cleanDefaultPermissions
      };
      save(
        {
          data: data as TeamWithOrgCreateInput
        },
        {
          onSuccess: () => {
            enqueueSnackbar("Team added successfully!", {
              variant: "success"
            });
            navigate("/teams");
          },
          onError: () => {
            enqueueSnackbar("Failed to add team!", {
              variant: "error"
            });
          }
        }
      );
    } catch (e) {
      enqueueSnackbar("Something went wrong. Please try again.", {
        variant: "error"
      });
      Sentry.captureException(e);
    }
  };

  return (
    <Container>
      <Toolbar title="Add Team" />
      <Form>
        <Loader isLoading={isSportLoading}>
          <Grid item container spacing={3}>
            <Grid item xs={12}>
              <StepProgressLine
                steps={steps}
                activeStepNumber={activeStepNumber}
                onEditClick={(active) => {
                  setTab(steps[active]);
                  setTimeout(() => {
                    trigger();
                  }, 50);
                }}
              />
            </Grid>
            {tab === "Team Details" && (
              <Grid item xs={12} data-testid="details_tab">
                <TeamDetailsForm
                  form={form}
                  disabled={false}
                  isEditing={false}
                  control={control}
                  isLoadingOptions={
                    isSportLoading &&
                    seasonOptionsLoading &&
                    levelOptionsLoading
                  }
                  sportOptions={sportOptions}
                  seasonOptions={seasonOptions}
                  levelOptions={levelOptions}
                  setSportsId={setSportId}
                  setSeasonId={setSeasonId}
                />
              </Grid>
            )}
            {tab === "Player Selection" && !playerUserOptionsLoading && (
              <Grid item xs={12} data-testid="players_tab">
                <PlayerSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={false}
                  isLoadingUsers={playerUserOptionsLoading}
                  userOptions={playerOptionsList}
                  setUserOptions={setPlayerOptionsList}
                  positionOptions={positionOptions || []}
                  players={players}
                  playersWithValues={playersWithValues}
                  setPlayersWithValues={setPlayersWithValues}
                  setPlayers={setPlayers}
                  roleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "PLAYER")
                      ?.roleId as string
                  }
                  parentRoleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "PARENT")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Coach Selection" && (
              <Grid item xs={12} data-testid="coaches_tab">
                <CoachSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={false}
                  isLoadingUsers={coachUserOptionsLoading}
                  userOptions={coachOptionsList}
                  setUserOptions={setCoachOptionsList}
                  subRoleOptions={coachesSubRole || []}
                  coaches={coaches}
                  coachesWithValues={coachesWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setCoaches={setCoaches}
                  roleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "COACH")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Manager Selection" && !managerUserOptionsLoading && (
              <Grid item xs={12} data-testid="managers_tab">
                <ManagerSelectionForm
                  form={form}
                  disabled={false}
                  isEditing={false}
                  isLoadingUsers={managerUserOptionsLoading}
                  userOptions={managerOptionsList}
                  setUserOptions={setManagerOptionsList}
                  subRoleOptions={managerSubRole || []}
                  managers={managers}
                  managersWithValues={managerWithValues}
                  setManagersWithValues={setManagerWithValues}
                  setManagers={setManagers}
                  roleId={
                    roleOptions?.data.roles?.find((r) => r.alias === "MANAGER")
                      ?.roleId as string
                  }
                />
              </Grid>
            )}
            {tab === "Permissions" && (
              <Grid item xs={12} data-testid="permission_tab">
                <PermissionSelectionForm
                  form={form}
                  defaultPermissions={defaultPermissions}
                  setDefaultPermissions={setDefaultPermissions}
                  playersWithValues={playersWithValues}
                  players={players}
                  coachesWithValues={coachesWithValues}
                  coaches={coaches}
                  managerWithValues={managerWithValues}
                  managers={managers}
                  setManagerWithValues={setManagerWithValues}
                  setCoachesWithValues={setCoachesWithValues}
                  setPlayersWithValues={(val) => {
                    setPlayersWithValues(val);
                  }}
                  playerRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "PLAYER")!
                  }
                  parentRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "PARENT")!
                  }
                  coachRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "COACH")!
                  }
                  coachSubRoles={coachesSubRole || []}
                  managerRole={
                    roleOptions!.data.roles!.find((r) => r.alias === "MANAGER")!
                  }
                  managerSubRoles={managerSubRole || []}
                  permissions={permissionsList}
                />
              </Grid>
            )}
          </Grid>
        </Loader>
        <DevTool control={control} placement="bottom-left" />
      </Form>
      <Footer
        additionalBtns={[
          activeStepNumber > 0 ? (
            <Button
              variant="admin-secondary"
              type="button"
              onClick={onBackClick}
              key={Math.random().toString()}
            >
              Back
            </Button>
          ) : null,
          <Button
            variant="admin-secondary"
            type="button"
            onClick={onCancelClick}
            key={Math.random().toString()}
          >
            Cancel
          </Button>,
          <Button
            variant="admin-primary"
            type="button"
            onClick={getPrimaryAction()}
            disabled={!isValid || isSaving}
            isLoading={isSaving}
            key={Math.random().toString()}
          >
            {activeStepNumber < steps.length - 1
              ? "Save & Continue"
              : "Save & Send Invites"}
          </Button>
        ]}
        isDisabled={!isValid || isSaving}
        isLoading={isSaving}
      />
      <ConfirmationDialog
        open={isConfirmationDialogOpen}
        title="Are you sure you want to cancel?"
        body="All of your current changes will be lost."
        onConfirm={onConfirmCancel}
        onCancel={onCancelCancel}
      />
    </Container>
  );
};
