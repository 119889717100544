import { FormInput } from "@components/FormInput";
import { FormMultiSelect } from "@components/FormMultiSelect";
import { FormSelect } from "@components/FormSelect";
import { Container } from "@components/crud/Container";
import { Form } from "@components/crud/Form";
import { Loader } from "@components/crud/Loader";
import { Toolbar } from "@components/crud/Toolbar";
import Grid from "@mui/material/Unstable_Grid2";
import {
  useAdminSkillSkillIdGet,
  useAdminPositionGet,
  useAdminSkillGet,
  ModelMedia,
  useAdminSportGet,
  useAdminSkillSkillIdDelete,
  ModelSkill
} from "@sportsgravyengineering/sg-api-react-sdk";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Typography, FormLabel } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useApiSelectOptions } from "@hooks/useApiSelectOptions";
import { useRecoilValue } from "recoil";
import { organizationAtom } from "@recoil/auth";
import { ConfirmationDialog } from "@components/ConfirmationDialog";
import { useSnackbar } from "notistack";
import { hasPermission } from "@services/Casbin";
import ReactPlayer from "react-player";

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
  marginBottom: "0.25rem",

  "& .MuiFormLabel-asterisk": {
    color: theme.palette.error.main
  }
}));

const StyledGrid = styled(Grid)(() => ({
  "& video": {
    objectFit: "cover"
  }
}));

export const SkillsView = () => {
  const navigate = useNavigate();
  const { skillId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [skillToDelete, setSkillToDelete] = useState<ModelSkill | null>(null);
  const [permissions, setPermissions] = useState({
    delete: false
  });

  const [files, setFiles] = useState<ModelMedia[]>([]);
  const [sportId, setSportId] = useState("");
  const organizationId = useRecoilValue(organizationAtom);
  const {
    data: skill,
    isFetching: isSkillFetching,
    error: error
  } = useAdminSkillSkillIdGet(skillId as string);
  useEffect(() => {
    if (error?.code == "ERR_BAD_REQUEST") navigate("/not-found");
  }, [error]);
  const { data: sports, isLoading: isSportLoading } = useAdminSportGet({
    organizationId: organizationId!
  });
  const sportOptions = useMemo(
    () =>
      sports?.data?.map((sport) => ({
        label: sport.name!,
        value: sport.sportId!
      })) || [],
    [sports]
  );
  const { options: positionOptions, isLoading: positionLoading } =
    useApiSelectOptions({
      api: useAdminPositionGet,
      dataField: "positions",
      labelField: "name",
      valueField: "positionId",
      params: {
        sportId: sportId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });
  const { options: parentSkillsOptions, isLoading: parentSkillLoading } =
    useApiSelectOptions({
      api: useAdminSkillGet,
      dataField: "skills",
      labelField: "name",
      valueField: "skillId",
      params: {
        sportId: sportId
      },
      options: {
        query: {
          enabled: !!sportId
        }
      }
    });

  useEffect(() => {
    if (!skill?.data?.sportId) {
      return;
    }

    setSportId(skill?.data?.sportId);
  }, [skill]);

  const { control, reset } = useForm();
  useEffect(() => {
    if (skill && !isSkillFetching) {
      reset(
        {
          name: skill?.data?.name,
          parent: skill?.data?.parent,
          description: skill?.data?.description,
          sportId: skill?.data?.sportId,
          parentId: skill?.data?.parentId,
          positions: skill?.data?.positions?.map(
            (position) => position.positionId
          )
        },
        {
          keepDefaultValues: true
        }
      );
      setFiles(skill?.data?.media || []);
    }
  }, [skill]);

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useAdminSkillSkillIdDelete();

  const onConfirmDelete = async () => {
    if (!skillToDelete?.skillId) return;
    try {
      await deleteAsync({ skillId: skillToDelete.skillId });
      enqueueSnackbar("Skill deleted successfully", { variant: "success" });
      setSkillToDelete(null);
      navigate("/skills");
    } catch (error) {
      enqueueSnackbar("Something went wrong! Unable to delete skill.", {
        variant: "error"
      });
      setSkillToDelete(null);
    }
  };

  useEffect(() => {
    const checkPermission = async (permissionId, permission) => {
      const res = await hasPermission(
        "ORGANIZATION",
        organizationId!,
        permissionId as string,
        permission as string
      );
      return res;
    };
    const fetchPermissions = async () => {
      const del = await checkPermission("general.organization", "DELETE");
      setPermissions({
        delete: del
      });
    };
    fetchPermissions();
  }, []);
  return (
    <Container>
      <Toolbar
        title="View Skill"
        backBtnClick={() => navigate("/skills")}
        editBtnClick={() => navigate(`/skills/${skillId}/edit`)}
        {...(permissions.delete && {
          deleteBtnClick: () => setSkillToDelete(skill!.data)
        })}
      />
      <Form>
        <Loader
          isLoading={
            isSportLoading ||
            isSkillFetching ||
            parentSkillLoading ||
            positionLoading
          }
        >
          <Grid container spacing={3}>
            <Grid data-testid="skill-name" xs={12} md={6}>
              <FormInput
                control={control}
                name="name"
                type="text"
                label="Name"
                required={true}
                disabled={true}
                rules={{
                  required: "Name is required"
                }}
              />
            </Grid>
            <Grid data-testid="skill-sport" xs={12} md={6}>
              <FormSelect
                control={control}
                name="sportId"
                label="Sport"
                required={true}
                options={sportOptions}
                disabled={true}
                rules={{
                  required: "Sport is required"
                }}
              />
            </Grid>
            <Grid data-testid="skill-parentSkill" xs={12} md={6}>
              <FormSelect
                control={control}
                name="parentId"
                label="Parent Skill"
                required={false}
                options={parentSkillsOptions}
                disabled={true}
              />
            </Grid>
            <Grid data-testid="skill-position" xs={12} md={6}>
              <FormMultiSelect
                control={control}
                name="positions"
                label="Positions"
                value="positions"
                required={false}
                disabled={true}
                options={positionOptions}
              />
            </Grid>
            <Grid data-testid="skill-description" xs={12} md={12}>
              <FormInput
                control={control}
                name="description"
                type="text"
                label="Description"
                required={true}
                multiline={true}
                disabled={true}
                rules={{
                  required: "Description is required"
                }}
              />
            </Grid>
            {files.length > 0 && (
              <Grid xs={12} md={12}>
                <StyledFormLabel>
                  <Typography display="inline" variant="formLabel">
                    {"Photo/Video Content"}
                  </Typography>
                </StyledFormLabel>
              </Grid>
            )}
            {files.map((item) => {
              return (
                <StyledGrid
                  data-testid="skill-media"
                  xs={12}
                  md={2}
                  key={Math.random().toString()}
                >
                  {item?.mimeType?.startsWith("image") ? (
                    <img
                      src={`${item.baseUrl}${item?.path}`}
                      style={{
                        width: "190px",
                        height: "190px"
                      }}
                    />
                  ) : (
                    <ReactPlayer
                      playing={true}
                      height={"190px"}
                      width={"190px"}
                      url={`${item?.baseUrl}${item?.path}`}
                      controls
                      style={{
                        objectFit: "cover",
                        width: "100%",
                        height: "100%"
                      }}
                    />
                  )}
                </StyledGrid>
              );
            })}
          </Grid>
        </Loader>
      </Form>
      <ConfirmationDialog
        open={!!skillToDelete}
        title="Delete Skill?"
        body={`Are you sure you want to delete ${skillToDelete?.name}?`}
        close={() => setSkillToDelete(null)}
        onConfirm={onConfirmDelete}
        onCancel={() => setSkillToDelete(null)}
        isConfirming={isDeleting}
        confirmBtnVariant="admin-warning"
        icon="warning"
      />
    </Container>
  );
};
